import { DependencyList, useMemo } from 'react';

import { getProfileStorage } from '..';
import { useAbstractStorage } from '../../../hooks';
import { ProfilesMainParams } from '../api';

interface Params extends Partial<ProfilesMainParams> {
  deps?: DependencyList;
}

/**
 * Используется для всех кроме currentUser.
 * Данные для currentUser брать из /main-frontend/packages/ant/src/plugins/currentProfileService.ts
 */
export const useProfileStorage = (params: Params) => {
  const { id, deps = [], ...rest } = params;
  const { storage: singleStorage } = useMemo(getProfileStorage, deps);

  const {
    data,
    loading: profilesLoading,
    error,
  } = useAbstractStorage(singleStorage, {
    autoFetchAndRefetch: Boolean(id),
    autoFetchParams: { userId: id || '', ...rest },
  });

  const loading = !data || profilesLoading;

  return {
    data,
    loading,
    error,
  };
};
