import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginated } from '../../types';
import { EmojiUuid, ReactionServiceModel, ReactionsTypesModel } from '../../types/models';
import { abstractStorageFactory } from '../../utils';
import { createReaction, deleteReaction, ReactionParams } from './api';

interface ReactionEffectParams extends Omit<ReactionParams, 'emojiUuid'> {
  emojiUuid?: EmojiUuid;
}

export const reactionEffect = createEffect<ReactionEffectParams, ReactionServiceModel | void, AxiosError>(
  ({ emojiUuid, ...restParams }) => {
    const response = emojiUuid ? createReaction({ emojiUuid, ...restParams }) : deleteReaction(restParams);

    return response.then(({ data }) => data);
  },
);

export const getReactionsTypesListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ReactionsTypesModel>,
    ReactionsTypesModel[],
    ReactionsTypesModel[]
  >({
    endpointBuilder: endpoints.reactions.reactionsTypesList,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
  });

  return { storage };
};

export const reactionsTypesStorage = getReactionsTypesListStorage();
