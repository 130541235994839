import React, { PropsWithChildren, createContext, useContext, FC } from 'react';

type PrivateRouteContextType = {
  isPrivateRoute: boolean;
  setIsPrivateRoute: React.Dispatch<React.SetStateAction<boolean>>;
};

type PrivateRouteProviderProps = {
  value: PrivateRouteContextType;
};

const defaultContextValue = {
  isPrivateRoute: false,
  setIsPrivateRoute: () => {},
};

const PrivateRouteContext = createContext<PrivateRouteContextType>(defaultContextValue);

export const PrivateRouteProvider: FC<PropsWithChildren<PrivateRouteProviderProps>> = (props) => {
  const { children, value } = props;

  return <PrivateRouteContext.Provider value={value}>{children}</PrivateRouteContext.Provider>;
};

export const usePrivateRoute = () => useContext(PrivateRouteContext);
