import { UserIdModel } from '../types/models';
import { ServiceRolesGroupDataId, ServiceRolesNames } from '../types/roles';

export const rolesEndpoints = {
  userPermissionsAll: (userId: UserIdModel) => `/api/roles/${userId}/permissions/all/`,
  serviceNamePermissions: (serviceName: ServiceRolesNames) => `/api/roles/${serviceName}/permissions/`,
  roles: () => `/api/roles/roles/`,
  usersGroups: () => `/api/roles/users-groups/`,
  servicesServiceNameGroupGroupId: (serviceName: ServiceRolesNames, groupId: ServiceRolesGroupDataId) =>
    `/api/roles/services/${serviceName}/group/${groupId}/`,
  servicesServiceNameUserUserId: (serviceName: ServiceRolesNames, userId: UserIdModel) =>
    `/api/roles/services/${serviceName}/user/${userId}/`,
  servicesServiceNameAssignRole: (serviceName: ServiceRolesNames) =>
    `/api/roles/services/${serviceName}/assign-role/`,
  servicesServiceNameRoles: (serviceName: ServiceRolesNames) => `/api/roles/services/${serviceName}/roles/`,
  servicesServiceNameList: (serviceName: ServiceRolesNames) => `/api/roles/services/${serviceName}/list/`,
};
