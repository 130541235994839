import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { Ordering, OrderingParams, PaginatedListResults, PaginationParams } from '../../types/api';
import { FetchSelectEntityParams } from '../../types/creatableMultiselect';
import { CreateDictionaryRecord, RecordResponse, Tag, TagRelation } from '../../types/models';
import { generateDictRecord, buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';
import { createTag, followTags, FollowTagsParams } from './api';

export interface TagsFilters extends Ordering<OrderingParams[]> {
  serviceObjects: TagRelation[];
  isFollowing: boolean;
}

export type GetTagsStorageParams = FetchSelectEntityParams & Partial<TagsFilters>;

export const getTagsDictStorage = () => {
  const storage = abstractStorageFactory<
    PaginatedListResults<Tag>,
    RecordResponse[],
    RecordResponse[],
    GetTagsStorageParams
  >({
    endpointBuilder: endpoints.tags.tagsList,
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataBuilder: ({ value, ordering, ...restParams }) => ({
      ordering: ordering || [OrderingParams.NameAtAsc],
      search: value,
      ...restParams,
    }),
    dataMapper: ({ results }) => results.map((item) => generateDictRecord(item)),
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const createTagEffect = createEffect<CreateDictionaryRecord, RecordResponse, AxiosError>(
    ({ attributes }) => {
      return createTag({ name: attributes.name }).then(({ data }) => generateDictRecord(data));
    },
  );

  storage.store.on(createTagEffect.doneData, (state, newTag) => ({
    ...state,
    data: [...state.data, newTag],
  }));

  return { storage, createTagEffect };
};

export const tagsDictsStorage = getTagsDictStorage();

export interface TagsListParams extends PaginationParams {
  search: string;
  serviceObjects: TagRelation[];
  isFollowing: boolean;
  ordering: OrderingParams[];
}

export const getTagsListStorage = () => {
  const storage = abstractStorageFactory<PaginatedListResults<Tag>, Tag[], Tag[], Partial<TagsListParams>>({
    endpointBuilder: ({ pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(endpoints.tags.tagsList(), { pageNumber, pageSize }),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataBuilder: (params) => params,
    dataMapper: ({ results }) => results,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount }),
  });

  const { store } = storage;

  const followTagsEffect = createEffect<FollowTagsParams, Tag[], AxiosError>((params) =>
    followTags(params).then(({ data }) => data),
  );

  store.on(followTagsEffect.doneData, (state, response) => {
    const params = storage.getLastRequestParams();
    const isStorageOnlyFollowing = params.isFollowing;

    return isStorageOnlyFollowing
      ? {
          ...state,
          data: response,
        }
      : state;
  });

  return { storage, followTagsEffect };
};
