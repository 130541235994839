import { ErrorResponseMessage } from './getErrorResponseMessage';

type VideoFileMetaResolve = {
  width: string;
  height: string;
  type: string;
};
type VideoFileMetaError = Pick<ErrorResponseMessage, 'error'>;
type VideoFileMeta = VideoFileMetaResolve | VideoFileMetaError;

export const getVideoFileMeta = (file: File): Promise<VideoFileMeta> => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();

    reader.onload = (e) => {
      const data = e.target?.result?.toString();

      if (data) {
        const video = document.createElement('video');

        video.setAttribute('preload', 'metadata');
        video.src = data;

        video.addEventListener('loadedmetadata', function getDimension() {
          const width = this.videoWidth;
          const height = this.videoHeight;

          resolve({ width: width.toString(), height: height.toString(), type: file.type });
        });

        const resolveWithPayload = () => resolve({ error: 'Не удалось загрузить метаданные' });

        video.addEventListener('error', resolveWithPayload);
        video.addEventListener('abort', resolveWithPayload);
      } else {
        reject(new Error('Нет метаданных'));
      }
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsDataURL(file);
  });
};
