import { endpoints } from '../../endpoints';
import { ServiceRolesGroupDataId } from '../../types';
import {
  PermissionRoleId,
  SiteId,
  SiteModel,
  SiteNavbarItemModel,
  SiteNavbarSortModel,
  SitePageId,
  SitePageModel,
  SiteServiceId,
  SiteServiceModel,
  SiteSlug,
  TagId,
  UserIdModel,
  PermissionRoleDetailModel,
} from '../../types/models';
import { api } from '../../utils';

export type CreateSiteParams = {
  name: string;
  description: string;
  isNavigationVisible: boolean;
  parent: SiteId;
  slug: SiteSlug;
};

export interface UpdateSiteParams extends CreateSiteParams {
  id: SiteId;
}

export const createSite = (data: CreateSiteParams) =>
  api.post<SiteModel>({
    url: endpoints.sitector.sites(),
    data,
  });

export const updateSite = (data: UpdateSiteParams) => {
  const { id, ...body } = data;

  return api.patch<SiteModel>({
    url: endpoints.sitector.sitesEntryId(id),
    data: body,
  });
};

export const deleteSite = (id: SiteId) =>
  api.delete<SiteModel>({
    url: endpoints.sitector.sitesEntryId(id),
  });

export const publishSite = (id: SiteId) =>
  api.post<SiteModel>({
    url: endpoints.sitector.sitesEntryIdPublish(id),
  });

export const unpublishSite = (id: SiteId) =>
  api.post<SiteModel>({
    url: endpoints.sitector.sitesEntryIdUnpublish(id),
  });

export const getSitesAuthors = () =>
  api.get<SiteModel>({
    url: endpoints.sitector.authors(),
  });

export interface CreateSitePageParams
  extends Pick<SitePageModel, 'name' | 'slug'>,
    Partial<Pick<SitePageModel, 'body' | 'status' | 'settings'>> {
  parentId?: SiteId;
  id: SiteId;
  tags?: TagId[];
}

export const createSitePage = (params: CreateSitePageParams) => {
  const { id, ...data } = params;

  return api.post<SitePageModel>({ url: endpoints.sitector.sitesEntryIdPages(id), data });
};

export interface UpdateSitePageParams
  extends Partial<Pick<SitePageModel, 'name' | 'body' | 'status' | 'settings'>> {
  pageId: SitePageId;
  parentId?: SiteId;
  tags?: TagId[];
}

export const updateSitePage = (params: UpdateSitePageParams) => {
  const { pageId, ...data } = params;

  return api.patch<SitePageModel>({ url: endpoints.sitector.sitesPagesPageId(pageId), data });
};

export interface CreateSiteServiceParams extends Pick<SiteServiceModel, 'name' | 'slug' | 'type'> {
  id: SiteId;
  parent?: SiteServiceId;
}

export const createSiteService = (params: CreateSiteServiceParams) => {
  const { id, ...data } = params;

  return api.post<SiteServiceModel>({
    url: endpoints.sitector.sitesEntryIdServices(id),
    data,
  });
};

export interface UpdateSiteServiceParams
  extends Partial<Pick<SiteServiceModel, 'name' | 'slug' | 'isVisible'>> {
  id: SiteId;
  serviceId: SiteServiceId;
  parent?: SiteServiceId;
}

export const updateSiteService = (params: UpdateSiteServiceParams) => {
  const { id, serviceId, ...data } = params;

  return api.patch<SiteServiceModel>({
    url: endpoints.sitector.sitesEntryIdServicesServiceObjectId(id, serviceId),
    data,
  });
};

export type DeleteSitePageParams = {
  pageId: SitePageId;
};

export const deleteSitePage = (params: DeleteSitePageParams) => {
  const { pageId } = params;

  return api.delete<void>({ url: endpoints.sitector.sitesPagesPageId(pageId) });
};

export type ToggleSitePageFavoriteParams = {
  id: SiteId;
  pageId: SitePageId;
};

export const addSitePageToFavorites = (params: ToggleSitePageFavoriteParams) => {
  const { pageId } = params;

  return api.post<void>({ url: endpoints.sitector.sitesEntryIdPagesPageIdFavorite(pageId) });
};

export const removeSitePageFromFavorites = (params: ToggleSitePageFavoriteParams) => {
  const { pageId } = params;

  return api.delete<void>({ url: endpoints.sitector.sitesEntryIdPagesPageIdFavorite(pageId) });
};

export type SortSiteNavbarParams = {
  id: SiteId;
  items: SiteNavbarSortModel[];
};

export type SortSiteNavbarResponse = {
  items: SiteNavbarItemModel[];
};

export const sortSiteNavbar = (params: SortSiteNavbarParams) => {
  const { id, items } = params;
  const data = { items };

  return api.put<SortSiteNavbarResponse>({
    url: endpoints.sitector.sitesEntryIdNavbar(id),
    data,
  });
};

export type SiteRole = { id: PermissionRoleId; users: UserIdModel[]; groups: ServiceRolesGroupDataId[] };
export type CreateSiteRolesParams = {
  id: SiteId;
  roles: SiteRole[];
  isOverride?: boolean;
};

export type SiteRoleAssignParams = {
  id: PermissionRoleId;
  users: UserIdModel[];
  groups: ServiceRolesGroupDataId[];
  isOverride: boolean;
};

export const createSiteRoles = ({ id, roles }: CreateSiteRolesParams) => {
  return api.post<SiteRole[]>({ url: endpoints.sitector.sitesEntryIdRoles(id), data: roles });
};

export type CreatePageRolesParams = {
  pageId: SitePageId;
  data: SiteRoleAssignParams[];
};

export const createPageRoles = ({ pageId, data }: CreatePageRolesParams) => {
  return api.post<PermissionRoleDetailModel[]>({ url: endpoints.sitector.pagesEntryIdRoles(pageId), data });
};
