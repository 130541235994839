import { IdeaCategoryId, IdeaModelId, IdeaTransitionNames } from '../types/models';

export const ideasEndpoints = {
  counts: () => '/api/ideas/counts/',
  list: () => '/api/ideas/list/',
  categoriesList: () => '/api/ideas/categories/list/',
  categoriesId: (categoryId: IdeaCategoryId) => `/api/ideas/categories/${categoryId}/`,
  categoriesUpdate: () => '/api/ideas/categories/update/',
  categoriesNew: () => '/api/ideas/categories/new/',
  idea: () => '/api/ideas/idea/',
  ideaId: (ideaId: IdeaModelId) => `/api/ideas/idea/${ideaId}/`,
  ideasAdminList: () => `/api/ideas/admin_roles/list/`,
  ideasAdminSave: () => '/api/ideas/admin_roles/save/',
  reviewersList: () => '/api/ideas/reviewers/batch/',
  ideasAdmin: (id: IdeaModelId) => `/api/ideas/admin_roles/${id}/`,
  ideaTransition: (ideaId: IdeaModelId, transition: IdeaTransitionNames) =>
    `/api/ideas/transition/${ideaId}/${transition}/`,
  ideaSubscription: (ideaId: IdeaModelId) => `/api/ideas/idea/${ideaId}/subscribe/`,
  ideaVote: (ideadId: IdeaModelId) => `/api/ideas/idea/${ideadId}/vote/`,
  ideaGenerateXlsx: (ideaId: IdeaModelId) => `/api/ideas/idea/${ideaId}/generate_xlsx/`,
  ideaReadStatusDescription: (ideaId: IdeaModelId) => `/api/ideas/idea/${ideaId}/read_status_description/`,
};
