import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginated } from '../../types/api';
import {
  CommentContentTypes,
  CommentDetailListModel,
  CommentObjectId,
  CommentSaveModel,
  SearchParams,
} from '../../types/models';
import { buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';
import {
  createComment,
  CreateCommentParams,
  deleteComment,
  DeleteCommentParams,
  updateComment,
  UpdateCommentParams,
} from './api';

export const getCommentSearchStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CommentDetailListModel>,
    CommentDetailListModel[],
    CommentDetailListModel[],
    SearchParams
  >({
    endpointBuilder: ({ pageNumber, pageSize }) => {
      return buildEndpointWithQueryParams(endpoints.comments.commentSearch(), { pageNumber, pageSize });
    },
    defaultValue: [],
    dataBuilder: ({ search }) => ({ search }),
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
    requestMethod: 'post',
  });

  return { storage };
};

export type GetCommentListStorageParams = { contentType: CommentContentTypes; objectId: CommentObjectId };

export const getCommentListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CommentDetailListModel>,
    CommentDetailListModel[],
    CommentDetailListModel[],
    GetCommentListStorageParams
  >({
    endpointBuilder: ({ contentType, objectId }) =>
      endpoints.comments.commentListContentTypeObjectId(contentType, objectId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const { refetchWithLastParams } = storage;

  const createCommentEffect = createEffect<CreateCommentParams, CommentSaveModel, AxiosError>((params) =>
    createComment<CommentSaveModel>(params).then(({ data }) => data),
  );

  const updateCommentEffect = createEffect<UpdateCommentParams, void, AxiosError>((params) =>
    updateComment<CommentSaveModel>(params).then(refetchWithLastParams),
  );

  const deleteCommentEffect = createEffect<DeleteCommentParams, void, AxiosError>((params) =>
    deleteComment(params).then(refetchWithLastParams),
  );

  return {
    storage,
    createCommentEffect,
    updateCommentEffect,
    deleteCommentEffect,
  };
};

export type CommentListStorage = ReturnType<typeof getCommentListStorage>;
