import { Rule } from '@vkph/ui';

export const validateMaxValue = (maxValue: number, message?: string): Rule => {
  return {
    validator: (_, value: number) => {
      if (value > maxValue) {
        return Promise.reject(new Error(message || `Пожалуйста, введите меньшее значение столбца`));
      }

      return Promise.resolve();
    },
  };
};

export const validateMinValue = (minValue: number, message?: string): Rule => {
  return {
    validator: (_, value: number) => {
      if (value < minValue) {
        return Promise.reject(new Error(message || `Пожалуйста, введите большее значение столбца`));
      }

      return Promise.resolve();
    },
  };
};
