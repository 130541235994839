import humps from 'humps';

import { endpoints } from '../../endpoints';
import { BaseFieldParams, DictPaginated, UiOptionDataExtended } from '../../types';
import { UserIdModel } from '../../types/models';
import {
  ServiceRolesGroupDataModel,
  ServiceRolesNames,
  ServiceRolesPermissionModelList,
  ServiceRolesPermissionsModel,
} from '../../types/roles';
import { buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';

export type RolesPermissionsStorageParams = { serviceName: ServiceRolesNames };
type FetchProfilePermissionsParams = {
  userId: UserIdModel;
  groupBy: ServiceRolesNames;
  serviceName: ServiceRolesNames[];
};

export const getRolesServiceNamePermissionsStorage = ({ serviceName }: RolesPermissionsStorageParams) => {
  const storage = abstractStorageFactory<
    ServiceRolesPermissionModelList,
    ServiceRolesPermissionModelList,
    ServiceRolesPermissionModelList
  >({
    endpointBuilder: () => endpoints.roles.serviceNamePermissions(serviceName),
    defaultValue: [],
  });

  return { storage };
};

export const getProfileRolesPermissionsStorage = () => {
  const storage = abstractStorageFactory<
    Partial<ServiceRolesPermissionsModel>,
    Partial<ServiceRolesPermissionsModel>,
    null,
    FetchProfilePermissionsParams
  >({
    endpointBuilder: ({ userId, groupBy, serviceName }) => {
      const stringServicesNames = serviceName.join(',');

      return buildEndpointWithQueryParams(endpoints.roles.userPermissionsAll(userId), {
        groupBy,
        serviceName: stringServicesNames,
      });
    },
    defaultValue: null,
    dataMapper: (data) => humps.decamelizeKeys(data, { separator: '-' }),
  });

  return { storage };
};

export type GetUsersGroupsStorageParams = Pick<BaseFieldParams, 'search'>;

export const getUsersGroupsListOptionsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ServiceRolesGroupDataModel>,
    UiOptionDataExtended<ServiceRolesGroupDataModel>[],
    UiOptionDataExtended<ServiceRolesGroupDataModel>[],
    Partial<GetUsersGroupsStorageParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.roles.usersGroups(), params),
    defaultValue: [],
    dataMapper: ({ items }) =>
      items.map((item) => ({
        value: item.id,
        name: item.displayName,
        data: item,
      })),
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
