import {
  EventsAllowedPermission,
  GroupsAllowedPermission,
  CmsAllowedPermission,
  NewsAllowedPermission,
  PermissionModel,
  PermissionsModel,
  SurveyAllowedPermission,
  CmsPersonalPagesAllowedPermission,
  IdeasAllowedPermission,
  UserProfileModel,
  SitesAllowedPermission,
  AllowedPermission,
  PermissionRoleId,
  UserIdModel,
} from './models';

export enum ServiceRolesNames {
  News = 'news',
  Survey = 'survey',
  Events = 'events',
  Groups = 'groups',
  CMSGroupsPages = 'cms-groups-pages',
  CMSPersonalPages = 'cms-personal-pages',
  Ideas = 'ideas',
  Site = 'sitector',
}

export type ServiceRolesGroupDataId = string;
export type ServiceRolesGroupDataOwnerId = string;
export type ServiceRolesRoleId = number;
export type ServiceRolesListItemId = string;

export type ServiceRolesGroupDataModel = {
  id: ServiceRolesGroupDataId;
  name: string;
  displayName: string;
  isSystemDefined: boolean;
  ownerServiceName: string;
  ownerServiceEntityId: ServiceRolesGroupDataOwnerId;
};

export enum ServiceRolesType {
  User = 'user',
  Group = 'group',
}

type ServiceRolesListItemBaseModel = {
  id: ServiceRolesListItemId;
  name: string;
  roleDescription: string;
};

export interface ServiceRolesListItemUserModel extends ServiceRolesListItemBaseModel {
  data?: UserProfileModel;
  type: ServiceRolesType.User;
}

export interface ServiceRolesListItemGroupModel extends ServiceRolesListItemBaseModel {
  data?: ServiceRolesGroupDataModel;
  type: ServiceRolesType.Group;
}

export type ServiceRolesListItemModel = ServiceRolesListItemUserModel | ServiceRolesListItemGroupModel;

export type ServiceRolesRoleModel = {
  id: ServiceRolesRoleId;
  name?: string;
  description?: string;
  role: AllowedPermission;
  isSuperRole: boolean;
};

export interface ServiceRolesUserModel extends ServiceRolesListItemUserModel {
  roleId: ServiceRolesRoleId;
  serviceRoles: ServiceRolesRoleModel[];
}

export interface ServiceRolesGroupModel extends ServiceRolesListItemGroupModel {
  roleId: ServiceRolesRoleId;
  serviceRoles: ServiceRolesRoleModel[];
}

export type ServiceRolesPermissionModel = PermissionsModel;
export type ServiceRolesPermissionModelList = ServiceRolesPermissionModel[];

export type ServiceRolesServicePermissionModel<T> = PermissionModel<T>[];
export type ServiceRolesPermissionsModel = {
  [ServiceRolesNames.News]: ServiceRolesServicePermissionModel<NewsAllowedPermission>;
  [ServiceRolesNames.Events]: ServiceRolesServicePermissionModel<EventsAllowedPermission>;
  [ServiceRolesNames.Survey]: ServiceRolesServicePermissionModel<SurveyAllowedPermission>;
  [ServiceRolesNames.Groups]: ServiceRolesServicePermissionModel<GroupsAllowedPermission>;
  [ServiceRolesNames.CMSGroupsPages]: ServiceRolesServicePermissionModel<CmsAllowedPermission>;
  [ServiceRolesNames.CMSPersonalPages]: ServiceRolesServicePermissionModel<CmsPersonalPagesAllowedPermission>;
  [ServiceRolesNames.Ideas]: ServiceRolesServicePermissionModel<IdeasAllowedPermission>;
  [ServiceRolesNames.Site]: ServiceRolesServicePermissionModel<SitesAllowedPermission>;
};

export type EventRoleAssignee = {
  id: PermissionRoleId;
  users: UserIdModel[];
  groups: ServiceRolesGroupDataId[];
  isOverride: boolean;
};
