import { AxiosError, AxiosResponse } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { BaseFieldParams, DictPaginated, PaginationParams, SearchFieldsParams } from '../../types';
import {
  SiteId,
  SiteModel,
  SiteNavbarItemModel,
  SitePageId,
  SitePageModel,
  SiteServiceSlug,
  SiteServiceId,
  SiteServiceModel,
  SiteServiceType,
  SiteSlug,
  SiteSearchField,
  SiteAuthorModel,
  PermissionRoleDetailModel,
  SiteRoleModel,
} from '../../types/models';
import { abstractStorageFactory, abstractStoreFactory, buildEndpointWithQueryParams } from '../../utils';
import {
  addSitePageToFavorites,
  createSite,
  createSitePage,
  CreateSitePageParams,
  CreateSiteParams,
  createSiteRoles,
  CreateSiteRolesParams,
  createSiteService,
  CreateSiteServiceParams,
  deleteSite,
  deleteSitePage,
  DeleteSitePageParams,
  publishSite,
  removeSitePageFromFavorites,
  sortSiteNavbar,
  SortSiteNavbarParams,
  SortSiteNavbarResponse,
  SiteRole,
  ToggleSitePageFavoriteParams,
  unpublishSite,
  updateSite,
  updateSitePage,
  UpdateSitePageParams,
  UpdateSiteParams,
  updateSiteService,
  UpdateSiteServiceParams,
  CreatePageRolesParams,
  createPageRoles,
} from './api';

export type SiteAdditionalParams = {
  ids?: SiteId[];
  status?: string;
};

export type SiteParams = PaginationParams &
  SearchFieldsParams<SiteSearchField> &
  SiteAdditionalParams &
  Pick<BaseFieldParams, 'search' | 'ordering' | 'authorsIds'>;

export const getSiteListStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<SiteModel>, SiteModel[], SiteModel[], SiteParams>({
    endpointBuilder: (params) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sites(), params);
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const paramsStore = abstractStoreFactory<Partial<SiteParams>>({});

  return {
    storage,
    paramsStore,
  };
};

export type GetSiteListStorage = ReturnType<typeof getSiteListStorage>;

type GetSiteParams = { id: SiteId };

export const getSiteStorage = () => {
  const storage = abstractStorageFactory<SiteModel, SiteModel, null, GetSiteParams>({
    endpointBuilder: ({ id }) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sitesEntryId(id));
    },
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};

export type GetSiteStorage = ReturnType<typeof getSiteStorage>;

type GetSiteSlugParams = { slug: SiteSlug };

export const getSiteSlugStorage = () => {
  const storage = abstractStorageFactory<SiteModel, SiteModel, null, GetSiteSlugParams>({
    endpointBuilder: ({ slug }) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sitesSlugEntrySlug(slug));
    },
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetSiteSlugStorage = ReturnType<typeof getSiteSlugStorage>;

export const createSiteEffect = createEffect<CreateSiteParams, SiteModel, AxiosError>((params) =>
  createSite(params).then(({ data }) => data),
);

export const updateSiteEffect = createEffect<UpdateSiteParams, SiteModel, AxiosError>((params) =>
  updateSite(params).then(({ data }) => data),
);

export const deleteSiteEffect = createEffect<{ id: SiteId }, SiteModel, AxiosError>(({ id }) =>
  deleteSite(id).then(({ data }) => data),
);

export const publishSiteEffect = createEffect<{ id: SiteId }, SiteModel, AxiosError>(({ id }) =>
  publishSite(id).then(({ data }) => data),
);

export const unpublishSiteEffect = createEffect<{ id: SiteId }, SiteModel, AxiosError>(({ id }) =>
  unpublishSite(id).then(({ data }) => data),
);

export const getSiteNavbarStorage = () => {
  const storage = abstractStorageFactory<
    Pick<DictPaginated<SiteNavbarItemModel>, 'items'>,
    SiteNavbarItemModel[],
    SiteNavbarItemModel[],
    GetSiteParams
  >({
    endpointBuilder: ({ id }) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sitesEntryIdNavbar(id));
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
  });

  const sortSiteNavbarEffect = createEffect<SortSiteNavbarParams, SortSiteNavbarResponse, AxiosError>(
    (params) => sortSiteNavbar(params).then(({ data }) => data),
  );

  storage.store.on(sortSiteNavbarEffect.doneData, (state, payload) => {
    return state.data ? { ...state, data: payload.items } : state;
  });

  return { storage, sortSiteNavbarEffect };
};

export type GetSiteNavbarStorage = ReturnType<typeof getSiteNavbarStorage>;

export const getSiteSlugNavbarStorage = () => {
  const storage = abstractStorageFactory<
    Pick<DictPaginated<SiteNavbarItemModel>, 'items'>,
    SiteNavbarItemModel[],
    SiteNavbarItemModel[],
    GetSiteSlugParams
  >({
    endpointBuilder: ({ slug }) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sitesSlugEntrySlugNavbar(slug));
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetSiteSlugNavbarStorage = ReturnType<typeof getSiteSlugNavbarStorage>;

export const createSitePageEffect = createEffect<CreateSitePageParams, SitePageModel, AxiosError>((params) =>
  createSitePage(params).then(({ data }) => data),
);

export const updateSitePageEffect = createEffect<UpdateSitePageParams, SitePageModel, AxiosError>((params) =>
  updateSitePage(params).then(({ data }) => data),
);

export const deleteSitePageEffect = createEffect<DeleteSitePageParams, void, AxiosError>((params) =>
  deleteSitePage(params).then(({ data }) => data),
);

type GetSitePageParams = {
  pageId: SitePageId;
};

export const getSitePageStorage = () => {
  const storage = abstractStorageFactory<SitePageModel, SitePageModel, null, GetSitePageParams>({
    endpointBuilder: ({ pageId }) => endpoints.sitector.sitesPagesPageId(pageId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

interface ToggleFavoriteParams extends ToggleSitePageFavoriteParams {
  isFavorite: boolean;
}

type GetSitePageSlugParams = {
  slug: SiteSlug;
  pageSlug: SiteServiceSlug;
};

export const getSitePageSlugStorage = (isMain: boolean) => {
  const storage = abstractStorageFactory<
    SitePageModel | DictPaginated<SitePageModel>,
    SitePageModel,
    null,
    GetSitePageSlugParams
  >({
    endpointBuilder: ({ slug, pageSlug }) => {
      return isMain
        ? buildEndpointWithQueryParams(endpoints.sitector.sitesSlugSiteSlugPages(slug), { isMain: true })
        : buildEndpointWithQueryParams(endpoints.sitector.pagesSlugPageSlug(pageSlug));
    },
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => ('items' in data ? data.items[0] : data),
  });

  const toggleFavoriteEffect = createEffect<ToggleFavoriteParams, AxiosResponse<void>, AxiosError>(
    (params) => {
      const { isFavorite, ...methodParams } = params;

      return isFavorite ? removeSitePageFromFavorites(methodParams) : addSitePageToFavorites(methodParams);
    },
  );

  storage.store.on(toggleFavoriteEffect.doneData, (state) => {
    return state.data ? { ...state, data: { ...state.data, isFavorite: !state.data.isFavorite } } : state;
  });

  return { storage, toggleFavoriteEffect };
};

export const createSiteServiceEffect = createEffect<CreateSiteServiceParams, SiteServiceModel, AxiosError>(
  (params) => createSiteService(params).then(({ data }) => data),
);

export const updateSiteServiceEffect = createEffect<UpdateSiteServiceParams, SiteServiceModel, AxiosError>(
  (params) => updateSiteService(params).then(({ data }) => data),
);

type SiteServiceParams = {
  id: SiteId;
  serviceId: SiteServiceId;
};

export const getSiteServiceStorage = () => {
  const storage = abstractStorageFactory<SiteServiceModel, SiteServiceModel, null, SiteServiceParams>({
    endpointBuilder: ({ id, serviceId }) => {
      return endpoints.sitector.sitesEntryIdServicesServiceObjectId(id, serviceId);
    },
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type BaseSiteServicesParams = {
  types: SiteServiceType[];
  parentId: SiteServiceId;
  isVisible: boolean;
  firstLevelOnly: boolean;
};

export type SiteServicesParams = Pick<SiteServiceModel, 'id'> &
  Partial<PaginationParams> &
  Partial<BaseSiteServicesParams> &
  Pick<BaseFieldParams, 'search'>;

export const getSiteServicesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SiteServiceModel>,
    SiteServiceModel[],
    SiteServiceModel[],
    SiteServicesParams
  >({
    endpointBuilder: ({ id, ...params }) => {
      return buildEndpointWithQueryParams(endpoints.sitector.sitesEntryIdServices(id), params);
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

type GetPagesListStorageParams = Pick<SiteServicesParams, 'id'> &
  Pick<BaseFieldParams, 'search' | 'ordering'>;

export const getPagesListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SitePageModel>,
    SitePageModel[],
    SitePageModel[],
    GetPagesListStorageParams
  >({
    endpointBuilder: ({ id, ...params }) =>
      buildEndpointWithQueryParams(endpoints.sitector.sitesEntryIdPages(id), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const paramsStore = abstractStoreFactory<Partial<SiteParams>>({});

  return { storage, paramsStore };
};

export type AuthorsSiteListParams = Partial<PaginationParams> & Pick<BaseFieldParams, 'search'>;

export const getAuthorsSiteListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SiteAuthorModel>,
    SiteAuthorModel[],
    SiteAuthorModel[],
    Partial<AuthorsSiteListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.sitector.authors(), params),
    dataMapper: ({ items }) => items,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const paramsStore = abstractStoreFactory<Partial<AuthorsSiteListParams>>({});

  return { storage, paramsStore };
};

export type AuthorsPageListParams = GetSiteParams &
  Partial<PaginationParams> &
  Pick<BaseFieldParams, 'search'>;

export const getAuthorsPageListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SiteAuthorModel>,
    SiteAuthorModel[],
    SiteAuthorModel[],
    AuthorsPageListParams
  >({
    endpointBuilder: ({ id, ...params }) =>
      buildEndpointWithQueryParams(endpoints.sitector.sitesEntryIdPagesAuthors(id), params),
    dataMapper: ({ items }) => items,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const paramsStore = abstractStoreFactory<Partial<AuthorsSiteListParams>>({});

  return { storage, paramsStore };
};

type GetSiteRolesStorageParams = Pick<CreateSiteRolesParams, 'id' | 'isOverride'> &
  Partial<Omit<SiteAllRolesStorageParams, 'displayName' | 'name'>>;

export const getSiteRolesStorage = () => {
  const storage = abstractStorageFactory<
    PermissionRoleDetailModel[],
    PermissionRoleDetailModel[],
    PermissionRoleDetailModel[],
    GetSiteRolesStorageParams
  >({
    endpointBuilder: ({ id, ...params }) =>
      buildEndpointWithQueryParams(endpoints.sitector.sitesEntryIdRoles(id), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const createSiteRolesEffect = createEffect<CreateSiteRolesParams, SiteRole[], AxiosError>((params) =>
    createSiteRoles(params).then(({ data }) => data),
  );

  return { storage, createSiteRolesEffect };
};

type GetPageRolesStorageParams = Pick<CreateSiteRolesParams, 'id' | 'isOverride'> &
  Partial<Omit<SiteAllRolesStorageParams, 'displayName' | 'name'>>;

export const getPageRolesStorage = () => {
  const storage = abstractStorageFactory<
    PermissionRoleDetailModel[],
    PermissionRoleDetailModel[],
    PermissionRoleDetailModel[],
    GetPageRolesStorageParams
  >({
    endpointBuilder: ({ id, ...params }) =>
      buildEndpointWithQueryParams(endpoints.sitector.pagesEntryIdRoles(id), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const createPageRolesEffect = createEffect<CreatePageRolesParams, PermissionRoleDetailModel[], AxiosError>(
    (params) => createPageRoles(params).then(({ data }) => data),
  );

  return { storage, createPageRolesEffect };
};

type SiteAllRolesStorageParams = Omit<SiteRoleModel, 'id'>;

export const getSiteAllRolesStorage = () => {
  const storage = abstractStorageFactory<
    SiteRoleModel[],
    SiteRoleModel[],
    SiteRoleModel[],
    Partial<SiteAllRolesStorageParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.sitector.sitesRoles(), params),
    defaultValue: [],
  });

  return { storage };
};

type GetPageAllRolesParams = { isInstanceSpecific: boolean };

export const getPageAllRolesStorage = () => {
  const storage = abstractStorageFactory<
    PermissionRoleDetailModel[],
    PermissionRoleDetailModel[],
    PermissionRoleDetailModel[],
    GetPageAllRolesParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.sitector.pagesRoles(), params),
    defaultValue: [],
  });

  return { storage };
};
