export enum FileLogoVariants {
  /** @deprecated TODO: B2BCORE-12261 */
  Desktop = 'desktop',
  /** @deprecated TODO: B2BCORE-12261 */
  Mobile = 'mobile',
  LogoDesktop = 'logo_desktop',
  LogoMobile = 'logo_mobile',
  PortalHat = 'portal_hat',
  UserAvatar = 'user_avatar',
  EmptyState = 'empty_state',
  PortalFavicon = 'portal_favicon',
  PortalFaviconDefault = 'portal_favicon_default',
}
