import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../../endpoints';
import { DictPaginated, OrderingParams } from '../../../types';
import { SkillId, SkillType, UserSkill, PeopleModel } from '../../../types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '../../../utils';
import {
  GetSkillsStorageParams,
  ApproveSkillParams,
  approveSkill,
  SaveSkillParams,
  saveSkill,
  DeleteSkillParams,
  deleteSkill,
} from './api';

type SkillParams = { skillId: SkillId; skillType: SkillType };

export const getSkillsStorage = ({ userId, skillType }: GetSkillsStorageParams) => {
  const storage = abstractStorageFactory<UserSkill[], UserSkill[], UserSkill[], GetSkillsStorageParams>({
    endpointBuilder: () =>
      buildEndpointWithQueryParams(endpoints.profiles.v2Skills(userId), {
        ordering: OrderingParams.CountDesc,
        type: skillType,
      }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (skills) => skills,
  });

  const { refetchWithLastParams } = storage;

  const approveSkillEffect = createEffect<ApproveSkillParams, unknown, AxiosError>((params) =>
    approveSkill({ userId, ...params }).then(refetchWithLastParams),
  );

  const saveSkillEffect = createEffect<SaveSkillParams, unknown, AxiosError>((params) =>
    saveSkill({ userId, ...params }).then(refetchWithLastParams),
  );

  const deleteSkillEffect = createEffect<DeleteSkillParams, unknown, AxiosError>((params) =>
    deleteSkill({ userId, ...params }).then(refetchWithLastParams),
  );

  return {
    storage,
    saveSkillEffect,
    deleteSkillEffect,
    approveSkillEffect,
  };
};

export type GetSkillsStorage = ReturnType<typeof getSkillsStorage>;

export const getSkillPeoplesStorage = ({ skillId, skillType }: SkillParams) => {
  const storage = abstractStorageFactory<
    DictPaginated<PeopleModel>,
    PeopleModel[],
    PeopleModel[],
    SkillParams
  >({
    endpointBuilder: () =>
      buildEndpointWithQueryParams(endpoints.profiles.v2SkillPeoples(skillId), { type: skillType }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
  });

  return {
    storage,
  };
};
