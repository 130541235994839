import { endpoints } from '../../endpoints';
import { BaseFieldParams, DictPaginated } from '../../types';
import {
  GroupId,
  GroupMemberModel,
  StructureChildren,
  StructureResponse,
  UserProfileModel,
} from '../../types/models';
import { api } from '../../utils';

export type GroupParams = {
  groupId: string;
  filter?: string;
  organizationId?: string;
  excludeRoot?: boolean;
};

export type SelectPath = {
  selectedPath: string[];
};

export type SelectParams = GroupParams & SelectPath;

export interface StructureSearchParams
  extends Pick<BaseFieldParams, 'search'>,
    Partial<Pick<UserProfileModel, 'townLiveRid'>> {
  isListSearch?: boolean;
  ids?: GroupId[];
}

export type GroupIdParams = {
  groupId: GroupId;
};

export const getRootEndpoint = () => endpoints.orgstructure.root();

export const getOrganizationsListEndpoint = () => endpoints.orgstructure.organizations();

export const getAffiliatesListEndpoint = () => endpoints.orgstructure.affiliates();

export const getGroupDetailedEndpoint = (groupId: string) => endpoints.orgstructure.detailed(groupId);

export const getStructureList = ({ groupId, filter, excludeRoot }: GroupParams) =>
  api.get<StructureResponse>({
    url: endpoints.orgstructure.list(),
    params: { id: groupId, filter, isActive: true, excludeRoot },
  });

export const getStructureBySearch = ({ search }: StructureSearchParams) =>
  api.post<DictPaginated<StructureChildren>>({
    url: endpoints.orgstructure.search(),
    data: { name: search, groupType: 'group' },
  });

export const getStructureListBySearch = (params: StructureSearchParams) =>
  api.get<StructureChildren[]>({
    url: endpoints.orgstructure.list(),
    params,
  });

export const getStructurePeopleBySearch = (params: StructureSearchParams) =>
  api.get<DictPaginated<GroupMemberModel>>({
    url: endpoints.orgstructure.people(),
    params: { ...params, isActive: true },
  });
