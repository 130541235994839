import { SiteId } from './sites.model';

export type CategoryExtraInfo = {
  color?: string;
};

export type CategoryModel<T> = {
  id: T;
  name: string;
  extraInfo: CategoryExtraInfo;
  isActive: boolean;
  siteId?: SiteId;
};

export enum CategoryActiveStatus {
  Active = 'active',
  Inactive = 'inactive',
  All = 'all',
}
