import { AxiosError, AxiosResponse } from 'axios';
import { createEffect } from 'effector';
import humps from 'humps';

import { endpoints } from '../../endpoints';
import {
  UiOptionData,
  DictPaginated,
  PaginationParams,
  Blog,
  BlogsSettingsModel,
  BlogTypes,
  EntityId,
} from '../../types';
import {
  FileStorageListEntryModel,
  BlogAlbumCategoryId,
  BlogAlbumCategoryModel,
  BlogId,
  BlogLastActivityModel,
  BlogModel,
  BlogRequestStatus,
  BlogSlugId,
  Role,
  SubscribeToBlogRequestModel,
  BlogImageInfoModel,
} from '../../types/models';
import { buildEndpointWithQueryParams, getFullName, abstractStorageFactory } from '../../utils';
import {
  acceptBlogInvite,
  rejectBlogInvite,
  BlogStorageParams,
  setBlogsSettingsMain,
  removeMember,
  getBlogParticipantsEndpoint,
  GetParticipantsParams,
  RemoveMemberParams,
  RemoveMemberFromBlogModel,
  getBlogInfo,
  UpdateBlogDescriptionParams,
  subscribeToBlog,
  BlogDataStorage,
  switchBlogNotification,
  SubscribeToBlogApiParams,
  subscribeToBlogRequest,
  BlogResponse,
  updateBlogDescription,
  blogEndpointsMap,
  SubscribeToBlogRequestParams,
  SwitchBlogNotificationsParams,
  GetBlogHistoryParams,
  CreateBlogAlbumCategoryParams,
  createBlogAlbumCategory,
  UpdateBlogAlbumCategoryParams,
  updateBlogAlbumCategory,
  deleteBlogAlbumCategory,
  updateBlogImageDescription,
  UpdateBlogImageDescriptionParams,
} from './api';

export const acceptInviteEffect = createEffect<BlogStorageParams, unknown, AxiosError>((params) =>
  acceptBlogInvite(params),
);

export const rejectInviteEffect = createEffect<BlogStorageParams, unknown, AxiosError>((params) =>
  rejectBlogInvite(params),
);

export const getBlogsSettingsMain = () => {
  const storage = abstractStorageFactory<BlogsSettingsModel, BlogsSettingsModel, null>({
    endpointBuilder: endpoints.asyncBlogs.settingsMain,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const setBlogsSettingsMainEffect = createEffect<BlogsSettingsModel, BlogsSettingsModel, AxiosError>(
    (params) => setBlogsSettingsMain(params).then(({ data }) => data),
  );

  storage.store.on(setBlogsSettingsMainEffect, (state, settings) => ({ ...state, ...settings }));

  return { storage, setBlogsSettingsMainEffect };
};

export const removeMemberFromBlogEffect = createEffect<
  RemoveMemberParams,
  RemoveMemberFromBlogModel,
  AxiosError
>((params) => removeMember(params).then(({ data }) => data));

export const getBlogMembersStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    DictPaginated<Blog.Member>,
    Blog.Member[],
    Blog.Member[],
    GetParticipantsParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(getBlogParticipantsEndpoint(blogId), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  storage.store.on(removeMemberFromBlogEffect.done, (state, { result }) => ({
    ...state,
    data: state.data.filter((member) => member.keycloakId !== result.keycloakId),
  }));

  return {
    storage,
  };
};

export type BlogMembersStorage = ReturnType<typeof getBlogMembersStorage>;

export type SingleBlogStorageParams<T> = {
  slugId: EntityId;
  type: BlogTypes;
  defaultValue?: T | null;
};

export const getSingleBlogStorage = <T extends BlogDataStorage>({
  slugId,
  type,
  defaultValue = null,
}: SingleBlogStorageParams<T>) => {
  const storage = abstractStorageFactory<T, T, T | null>({
    endpointBuilder: () => blogEndpointsMap[type](slugId as never),
    defaultValue,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      data.permissionsV2 ? { ...data, permissionsV2: humps.decamelizeKeys(data.permissionsV2) } : data,
  });
  const { refetchWithLastParams } = storage;

  const getStateBlogId = (): BlogId => {
    const { data } = storage.store.getState();

    return Number(data?.id);
  };

  const updateDescriptionEffect = createEffect<UpdateBlogDescriptionParams, unknown, AxiosError>({
    handler: (params) =>
      updateBlogDescription({ blogId: getStateBlogId(), ...params }).then(refetchWithLastParams),
  });

  const subscribeToBlogEffect = createEffect<SubscribeToBlogApiParams, unknown, AxiosError>((params) =>
    subscribeToBlog(params),
  );

  const subscribeToBlogRequestEffect = createEffect<
    SubscribeToBlogRequestParams,
    SubscribeToBlogRequestModel | void,
    AxiosError
  >((params) => subscribeToBlogRequest(params).then((response) => response.data));

  const updateBlogPermissionsEffect = createEffect<void, AxiosResponse<BlogResponse>, AxiosError>(() =>
    getBlogInfo<BlogResponse>({ blogId: getStateBlogId() }),
  );

  const switchBlogNotificationEffect = createEffect<
    SwitchBlogNotificationsParams,
    { notify: boolean },
    AxiosError
  >({
    handler: (params) => switchBlogNotification({ blogId: getStateBlogId(), ...params }),
  });

  storage.store.on(switchBlogNotificationEffect.done, (state, { result: { notify } }) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          notify,
        },
      };
    }

    return state;
  });

  storage.store.on(subscribeToBlogEffect.done, (state, { params: { follow } }) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          isSubscribed: follow,
        },
      };
    }

    return state;
  });

  storage.store.on(subscribeToBlogRequestEffect.doneData, (state, payload) => {
    if (state.data) {
      if (payload) {
        return {
          ...state,
          data: {
            ...state.data,
            requestStatus: BlogRequestStatus.Pending,
            requestId: payload.requestId,
          },
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          requestStatus: null,
          requestId: null,
        },
      };
    }

    return state;
  });

  storage.store.on(updateBlogPermissionsEffect.done, (state, { result }) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          permissions: result.data?.permissions,
        },
      };
    }

    return state;
  });

  return {
    storage,
    updateDescriptionEffect,
    subscribeToBlogEffect,
    subscribeToBlogRequestEffect,
    updateBlogPermissionsEffect,
    switchBlogNotificationEffect,
  };
};

export type BlogStorage = ReturnType<typeof getSingleBlogStorage<BlogModel>>;

export const getBlogMembersOptionsDataStorage = (blogId: BlogId) => {
  const storage = abstractStorageFactory<
    DictPaginated<Blog.Member>,
    UiOptionData[],
    UiOptionData[],
    GetParticipantsParams
  >({
    endpointBuilder: (params) => {
      return buildEndpointWithQueryParams(endpoints.asyncBlogs.blogParticipants(blogId), {
        roles: [Role.Admin, Role.Author, Role.Follower, Role.Moderator].join(','),
        isActive: true,
        ...params,
      });
    },
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) =>
      items.map((member) => ({
        value: member.keycloakId,
        label: getFullName(member),
        job: member.position,
        avatar: member.avatar,
        data: member,
      })),
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

type GetBlogLastActivityStorageParams = GetBlogHistoryParams & Partial<Pick<PaginationParams, 'pageSize'>>;

export const getBlogLastActivityStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<BlogLastActivityModel>,
    BlogLastActivityModel[],
    BlogLastActivityModel[],
    GetBlogLastActivityStorageParams
  >({
    endpointBuilder: ({ blogId, ...params }) => {
      return buildEndpointWithQueryParams(endpoints.asyncBlogs.blogIdHistory(blogId), params);
    },
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
  });

  return { storage };
};

export const createBlogAlbumCategoryEffect = createEffect<
  CreateBlogAlbumCategoryParams,
  BlogAlbumCategoryModel,
  AxiosError
>((params) => createBlogAlbumCategory(params).then(({ data }) => data));

export const updateBlogAlbumCategoryEffect = createEffect<
  UpdateBlogAlbumCategoryParams,
  BlogAlbumCategoryModel,
  AxiosError
>((params) => updateBlogAlbumCategory(params).then(({ data }) => data));

type GetBlogAlbumCategoriesParams = { blogId?: BlogId };
export type DeleteBlogAlbumCategoryParams = {
  categoryId: BlogAlbumCategoryId;
};

export const getBlogAlbumCategoriesStorage = ({ blogSlugId }: { blogSlugId?: BlogSlugId } = {}) => {
  const storage = abstractStorageFactory<
    BlogAlbumCategoryModel[],
    BlogAlbumCategoryModel[],
    BlogAlbumCategoryModel[],
    GetBlogAlbumCategoriesParams
  >({
    endpointBuilder: (params) => {
      return blogSlugId
        ? endpoints.asyncBlogs.blogSlugAlbumsCategories(blogSlugId)
        : endpoints.asyncBlogs.blogIdAlbumsCategories(params.blogId);
    },
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const { store } = storage;

  const deleteBlogAlbumCategoryEffect = createEffect<DeleteBlogAlbumCategoryParams, void, AxiosError>(
    (params) => deleteBlogAlbumCategory(params).then(({ data }) => data),
  );

  store
    .on(createBlogAlbumCategoryEffect.doneData, (state, newCategory) => ({
      ...state,
      data: [...state.data, newCategory],
    }))
    .on(updateBlogAlbumCategoryEffect.doneData, (state, updatedCategory) => ({
      ...state,
      data: state.data.map((category) =>
        category.id === updatedCategory.id ? { ...category, ...updatedCategory } : category,
      ),
    }))
    .on(deleteBlogAlbumCategoryEffect.done, (state, { params: { categoryId } }) => ({
      ...state,
      data: state.data.filter((category) => category.id !== categoryId),
    }));

  return {
    storage,
    createBlogAlbumCategoryEffect,
    updateBlogAlbumCategoryEffect,
    deleteBlogAlbumCategoryEffect,
  };
};

export type GetBlogAlbumCategoriesStorage = ReturnType<typeof getBlogAlbumCategoriesStorage>;

type GetBlogImageIdStorageParams = { imageId: EntityId };

export const getImageFileStorage = () => {
  const storage = abstractStorageFactory<
    BlogImageInfoModel,
    BlogImageInfoModel,
    null,
    GetBlogImageIdStorageParams
  >({
    endpointBuilder: ({ imageId }) => endpoints.asyncBlogs.blogAlbumImageId(imageId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const setDescriptionImageEffect = createEffect<
    UpdateBlogImageDescriptionParams,
    FileStorageListEntryModel,
    AxiosError
  >((params) => updateBlogImageDescription(params).then(({ data }) => data));

  storage.store.on(setDescriptionImageEffect.doneData, (state, data) => {
    if (state.data && state.data.fileStorageImage) {
      const fileStorageImageId = humps.camelize(state.data.fileStorageImageId);

      return {
        ...state,
        data: {
          ...state.data,
          fileStorageImage: {
            ...state.data.fileStorageImage,
            [fileStorageImageId]: {
              ...data,
            },
          },
        },
      };
    }

    return state;
  });

  return { storage, setDescriptionImageEffect };
};
