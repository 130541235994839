import { Dictionaries, DictRecordId } from '../types/models';

export const dictionariesEndpoints = {
  dicts: () => `/api/dictionaries/`,
  recordsList: () => `/api/dictionaries/records/list/`,
  dictionaryName: (dictionaryName: Dictionaries.Name) => `/api/dictionaries/${dictionaryName}/`,
  dictionaryNameRecords: (dictionaryName: Dictionaries.Name) =>
    `/api/dictionaries/${dictionaryName}/records/`,
  dictionaryNameRecordsNew: (dictionaryName: Dictionaries.Name) =>
    `/api/dictionaries/${dictionaryName}/records/new/`,
  recordsRecordId: (recordId: DictRecordId) => `/api/dictionaries/records/${recordId}/`,
};
