import { AxiosError, AxiosResponse } from 'axios';
import { Effect } from 'effector';

import { endpoints } from '../../endpoints';
import {
  BaseFieldParams,
  OrderingParams,
  PaginationParams,
  BlogsSettingsModel,
  BlogTypes,
  EntityContentData,
  EntityId,
  Ordering,
} from '../../types';
import {
  BlogAlbumCategoryId,
  BlogAlbumCategoryModel,
  BlogId,
  BlogModel,
  BlogRegistrationId,
  BlogRequestId,
  FileStorageListEntryModel,
  PermissionsEnum,
  Role,
  SubscribeToBlogRequestModel,
  UserIdModel,
} from '../../types/models';
import { api, AbstractStorage } from '../../utils';
import { UserIdParams } from '../profile';

export type MemberInviteId = number;

export type BlogStorageParticipantsParams = { roles?: string };

export type RemoveMemberParams = UserIdParams & BlogStorageParams;
export type GetParticipantsParams = GetParticipantsFilters & BlogStorageParticipantsParams & PaginationParams;

type SwitchBlogNotificationsApiParams = SwitchBlogNotificationsParams & BlogStorageParams;
export type SwitchBlogNotificationsParams = { notify: boolean; type: BlogTypes };

export type BlogStorageParams = { blogId: BlogId };
export type UpdateBlogDescriptionParams = { expandedDescription: EntityContentData };
export type GetBlogParams = BlogStorageParams;

export type SubscribeToBlogParams = { follow: boolean; type: BlogTypes };
export type SubscribeToBlogRequestBaseParams = {
  requestId?: BlogRequestId;
  follow: boolean;
};
export type SubscribeToBlogApiParams = SubscribeToBlogParams & BlogStorageParams;
export type SubscribeToBlogRequestParams = SubscribeToBlogRequestBaseParams & BlogStorageParams;

export type BlogDataStorage = { id: number; permissionsV2?: BlogModel['permissionsV2'] };
export interface BlogStorage<T extends BlogDataStorage> {
  storage: AbstractStorage<T, T, T | null, void>;
  updateDescriptionEffect: Effect<UpdateBlogDescriptionParams, unknown, AxiosError>;
  subscribeToBlogEffect: Effect<SubscribeToBlogApiParams, unknown, AxiosError>;
  updateBlogPermissionsEffect: Effect<void, AxiosResponse<BlogResponse>, AxiosError>;
  switchBlogNotificationEffect: Effect<SwitchBlogNotificationsParams, { notify: boolean }, AxiosError>;
  subscribeToBlogRequestEffect: Effect<
    SubscribeToBlogRequestParams,
    SubscribeToBlogRequestModel | void,
    AxiosError
  >;
}

export type BlogResponse = {
  permissions: PermissionsEnum[];
};

export type GetParticipantsFilters = Pick<BaseFieldParams, 'search'> &
  Partial<Ordering<OrderingParams.JoinedAtDesc | OrderingParams.JoinedAtAsc>>;

export type RemoveMemberFromBlogModel = {
  id: MemberInviteId;
  keycloakId: UserIdModel;
  role: Role;
};

export type GetBlogHistoryParams = BlogStorageParams;

export const blogEndpointsMap = {
  [BlogTypes.BlogsView]: endpoints.asyncBlogs.blogId,
  [BlogTypes.BlogsViewSlug]: endpoints.asyncBlogs.blogSlug,
  [BlogTypes.BlogsList]: endpoints.asyncBlogs.list,
  [BlogTypes.BlogSubscribe]: endpoints.asyncBlogs.subscriptionsMyBlog,
  [BlogTypes.BlogSubscribeRequest]: endpoints.asyncBlogs.blogSubscribeRequest,
};

export const exportBlogRegistrationsToXlsx = (blogId: BlogId, registrationId: BlogRegistrationId) => {
  return api.get<Blob>({
    url: endpoints.asyncBlogs.blogRegistrationExportXlsx(blogId, registrationId),
    responseType: 'blob',
  });
};

export const acceptBlogInvite = ({ blogId }: BlogStorageParams) => {
  return api.post({ url: endpoints.asyncBlogs.blogIdAcceptInvite(blogId) });
};

export const rejectBlogInvite = ({ blogId }: BlogStorageParams) => {
  return api.post({ url: endpoints.asyncBlogs.blogRejectInvite(blogId) });
};

export const setBlogsSettingsMain = (data: BlogsSettingsModel) => {
  return api.post<BlogsSettingsModel>({ url: endpoints.asyncBlogs.settingsMain(), data });
};

export const removeMember = ({ blogId, userId }: RemoveMemberParams) => {
  return api.delete<RemoveMemberFromBlogModel>({
    url: endpoints.asyncBlogs.subscriptionsBlog(userId, blogId),
  });
};

export const getBlogParticipantsEndpoint = (blogId: BlogId) => endpoints.asyncBlogs.blogParticipants(blogId);

export const getBlogInfo = <T = unknown>({ blogId }: BlogStorageParams): Promise<AxiosResponse<T>> => {
  return api.get({ url: blogEndpointsMap[BlogTypes.BlogsView](blogId) });
};

export const subscribeToBlog = ({ blogId, follow, type }: SubscribeToBlogApiParams) => {
  const method = follow ? 'put' : 'delete';

  return api[method]({ url: blogEndpointsMap[type](blogId as never) });
};

export const subscribeToBlogRequest = ({ blogId, follow, requestId }: SubscribeToBlogRequestParams) => {
  const getUrl = blogEndpointsMap[BlogTypes.BlogSubscribeRequest];

  if (follow) {
    return api.post<SubscribeToBlogRequestModel>({ url: getUrl(blogId) });
  }

  return api.delete<void>({ url: getUrl(blogId, requestId) });
};

function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const switchBlogNotification = ({ blogId, notify, type }: SwitchBlogNotificationsApiParams) =>
  delay(1200).then(() => ({ blogId, notify, type }));

type UpdateBlogDescriptionApiParams = UpdateBlogDescriptionParams & BlogStorageParams;

export const updateBlogDescription = ({ blogId, expandedDescription }: UpdateBlogDescriptionApiParams) =>
  api.patch({
    url: endpoints.asyncBlogs.blogId(blogId),
    data: { expandedDescription },
  });

export type CreateBlogAlbumCategoryParams = {
  blogId: BlogId;
  name: string;
  markerColor: string;
};

type CategoryParam = {
  categoryId: BlogAlbumCategoryId;
};

export type UpdateBlogAlbumCategoryParams = Omit<CreateBlogAlbumCategoryParams, 'blogId'> & CategoryParam;

export type GetBlogAlbumCategoryParams = CategoryParam;

export type DeleteBlogAlbumCategoryParams = Pick<UpdateBlogAlbumCategoryParams, 'categoryId'>;

export const createBlogAlbumCategory = ({ blogId, ...restParams }: CreateBlogAlbumCategoryParams) =>
  api.post<BlogAlbumCategoryModel>({
    url: endpoints.asyncBlogs.blogIdAlbumsCategories(blogId),
    data: restParams,
  });

export const deleteBlogAlbumCategory = ({ categoryId }: DeleteBlogAlbumCategoryParams) =>
  api.delete<void>({
    url: endpoints.asyncBlogs.albumsCategoriesId(categoryId),
  });

export const updateBlogAlbumCategory = ({ categoryId, ...restParams }: UpdateBlogAlbumCategoryParams) =>
  api.put<BlogAlbumCategoryModel>({
    url: endpoints.asyncBlogs.albumsCategoriesId(categoryId),
    data: restParams,
  });

export type UpdateBlogImageDescriptionParams = {
  imageId: EntityId;
  fileImage: FileStorageListEntryModel;
};

export const updateBlogImageDescription = ({ imageId, fileImage }: UpdateBlogImageDescriptionParams) =>
  api.put<FileStorageListEntryModel>({
    url: endpoints.filestorage.fileId(String(imageId)),
    data: fileImage,
  });
