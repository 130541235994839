import { UserIdModel, TimelineId } from '../types/models';

export const timelinesEndpoints = {
  all: () => '/api/timelines/all/',
  blogs: () => '/api/timelines/blogs/',
  blogsSubscribed: () => '/api/timelines/blogs/subscribed/',
  records: (recordId: TimelineId) => `/api/timelines/records/${recordId}/`,
  my: () => '/api/timelines/my/',
  settingsAll: () => '/api/timelines/settings/all/',
  settingsReset: () => '/api/timelines/settings/reset/',
  userId: (userId: UserIdModel) => `/api/timelines/user/${userId}/`,
  followingIsSubscribed: () => '/api/timelines/following/is_subscribed/',
  usersIdFollow: (userId: UserIdModel) => `/api/timelines/users/${userId}/follow/`,
  usersIdUnfollow: (userId: UserIdModel) => `/api/timelines/users/${userId}/unfollow/`,
  usersIdFollowers: (userId: UserIdModel) => `/api/timelines/users/${userId}/followers/`,
  usersIdFollowings: (userId: UserIdModel) => `/api/timelines/users/${userId}/followings/`,
  usersIdAllRelationsCounter: (userId: UserIdModel) =>
    `/api/timelines/users/${userId}/all_relations/counter/`,
};
