import { endpoints } from '../../endpoints';
import {
  DictionaryType,
  FileStorageEntryId,
  ListColumnFieldType,
  ListColumnId,
  ListColumnModel,
  ListId,
  ListRowId,
  ListRowModel,
  ListRowValues,
} from '../../types/models';
import { api } from '../../utils';

export type DeleteListRowParams = {
  listId: ListId;
  listRowId: ListRowId;
};

export type DeleteListRowsBatchParams = {
  listId: ListId;
  rowIds: ListRowId[];
};

type CreateRowParams = {
  id: ListId;
  rowValues: ListRowValues;
  insertAfterId?: ListRowId;
};

type TextFormFieldOptionsParams = {
  default: string;
  maxLength: number;
};

type BooleanFormFieldOptionsParams = {
  default: boolean;
  trueValueName?: string;
  falseValueName?: string;
};

type NumericFormFieldOptionsParams = {
  default: number;
  minValue?: number;
  maxValue?: number;
};

type DictionaryFormFieldOptionsParams = {
  dictionaryType: DictionaryType;
};

type FileFormFieldOptionsParams = {
  folderId: FileStorageEntryId;
};

export type CreateListColumnParams = {
  id: ListId;
  name: string;
  fieldType: ListColumnFieldType;
  isRequired: boolean;
  fieldOptions:
    | TextFormFieldOptionsParams
    | BooleanFormFieldOptionsParams
    | NumericFormFieldOptionsParams
    | DictionaryFormFieldOptionsParams
    | FileFormFieldOptionsParams;
};

export type ListColumnParams = {
  id: ListId;
  columnId: ListColumnId;
};

export interface UpdateListColumnParams extends Omit<CreateListColumnParams, 'id'>, ListColumnParams {}

export interface UpdateListColumnsOrderParams {
  id: ListId;
  order: ListColumnId[];
}

export interface UpdateListRowParams {
  id: ListId;
  rowId: ListRowId;
  rowValues: ListRowValues;
}

export const createRow = ({ id, ...data }: CreateRowParams) => {
  return api.post<ListRowModel>({
    url: endpoints.lists.listIdRows(id),
    data,
  });
};

export const deleteListRow = ({ listId, listRowId }: DeleteListRowParams) =>
  api.delete<void>({
    url: endpoints.lists.listIdRowsId(listId, listRowId),
  });

export const deleteListRows = ({ listId, ...data }: DeleteListRowsBatchParams) =>
  api.post<void>({
    url: endpoints.lists.listIdRowsDeleteBatch(listId),
    data,
  });

export const createColumn = ({ id, ...data }: CreateListColumnParams) => {
  return api.post<ListColumnModel>({
    url: endpoints.lists.listIdColumns(id),
    data,
  });
};

export const updateColumn = ({ id, columnId, ...data }: UpdateListColumnParams) => {
  return api.put<ListColumnModel>({
    url: endpoints.lists.listIdColumnsId(id, columnId),
    data,
  });
};

export const deleteColumn = ({ id, columnId }: ListColumnParams) => {
  return api.delete({
    url: endpoints.lists.listIdColumnsId(id, columnId),
  });
};

export const toggleVisibleColumn = ({ id, columnId }: ListColumnParams) => {
  return api.post<ListColumnModel>({
    url: endpoints.lists.listIdColumnsIdToggleVisible(id, columnId),
  });
};

export const copyColumn = ({ id, columnId }: ListColumnParams) => {
  return api.post<ListColumnModel>({
    url: endpoints.lists.listIdColumnsIdCopy(id, columnId),
  });
};

export const updateColumnsOrder = ({ id, ...data }: UpdateListColumnsOrderParams) => {
  return api.post({
    url: endpoints.lists.listIdColumnsOrdering(id),
    data,
  });
};

export const updateListRow = ({ id, rowId, ...data }: UpdateListRowParams) => {
  return api.put<ListRowModel>({
    url: endpoints.lists.listIdRowsId(id, rowId),
    data,
  });
};
