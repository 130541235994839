import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import isSameDay from 'date-fns/isSameDay';
import isSameMinute from 'date-fns/isSameMinute';
import isSameYear from 'date-fns/isSameYear';
import isToday from 'date-fns/isToday';
import isValid from 'date-fns/isValid';
import isYesterday from 'date-fns/isYesterday';
import ru from 'date-fns/locale/ru';
import setDefaultOptions from 'date-fns/setDefaultOptions';

import { capitalizeFirstLetter, getTimeZoneValue } from '..';

// Set global locale:
setDefaultOptions({ locale: ru });

export const utcToLocalDate = (date: string, dateFormat = 'dd.MM.yyyy в H:mm') => {
  if (!isValid(new Date(date))) {
    return '';
  }

  const timeZoneValue = getTimeZoneValue();
  const localDate = utcToZonedTime(date, timeZoneValue);

  return format(localDate, dateFormat);
};

export const getValidDate = (date: Date | string) => {
  if (isDate(date)) {
    return date as Date;
  }

  return new Date(date);
};

export const getFormattedDate = (date?: string | Date, dateFormat = 'dd MMMM') => {
  return date && isValid(new Date(date)) ? format(new Date(date), dateFormat) : '';
};

export const getFormattedRangeDate = (dateList: Date[], dateFormat = 'yyyy-MM-dd') =>
  dateList.map((date) => getFormattedDate(date, dateFormat));

export const getFormattedDateTime = (date: string | Date) => format(getValidDate(date), 'HH:mm dd.MM.yyyy');
export const getFormattedTime = (date: string | Date) => format(getValidDate(date), 'HH:mm');

export const getFormattedDateRange = (since: string | Date, till: string | Date) => {
  if (isSameDay(getValidDate(since), getValidDate(till))) {
    return getFormattedDate(since);
  }

  const dateFormat = 'dd MMMM yyyy';

  if (!isSameYear(new Date(), getValidDate(since))) {
    return `${getFormattedDate(since, dateFormat)} – ${getFormattedDate(till, dateFormat)}`;
  }

  if (!isSameYear(new Date(), getValidDate(till))) {
    return `${getFormattedDate(since)} – ${getFormattedDate(till, dateFormat)}`;
  }

  return `${getFormattedDate(since)} – ${getFormattedDate(till)}`;
};

export const getFormattedTimeRange = (since: string | Date, till: string | Date) =>
  isSameMinute(getValidDate(since), getValidDate(till))
    ? getFormattedTime(since)
    : `${getFormattedTime(since)} – ${getFormattedTime(till)}`;

export const getFormatDateTimeForPreview = (since: string | Date, till: string | Date, delimiter = ', ') =>
  `${getFormattedDateRange(since, till)}${delimiter}${getFormattedTimeRange(since, till)}`;

export const getFormattedTimeWithOptionalDate = (date: string | Date, current: string | Date) =>
  isSameDay(getValidDate(date), getValidDate(current))
    ? getFormattedTime(date)
    : `${getFormattedTime(date)} (${format(getValidDate(date), 'dd.MM')})`;

export const getFormattedRangeWithOptionalDate = (
  since: string | Date,
  till: string | Date,
  current: string | Date = since,
) =>
  `${getFormattedTimeWithOptionalDate(since, current)} – ${getFormattedTimeWithOptionalDate(till, current)}`;

enum DateWords {
  Yesterday = 'вчера',
  Today = 'сегодня',
}

type FormattedDateToWord = {
  date: string | Date;
  dateFormat?: string;
  dateWordFormat?: string;
  isCapitalize?: boolean;
};

export const getFormattedDateToWord = (params: FormattedDateToWord): string => {
  const {
    date,
    dateFormat = 'dd.MM.yyyy в HH:mm',
    dateWordFormat = ' в HH:mm',
    isCapitalize = false,
  } = params;
  const withWordFormat = (dateWord: DateWords) => {
    const formatDate = `${dateWord}${dateWordFormat}`;

    return isCapitalize ? capitalizeFirstLetter(formatDate) : formatDate;
  };
  const validDate = getValidDate(date);

  if (isYesterday(validDate)) {
    return format(validDate, withWordFormat(DateWords.Yesterday));
  }

  if (isToday(validDate)) {
    return format(validDate, withWordFormat(DateWords.Today));
  }

  return format(validDate, dateFormat);
};

export const getFormattedDateWithOrWithoutDate = (date: string | Date) => {
  const validDate = getValidDate(date);
  const formattedDate = isToday(validDate) ? 'HH:mm' : 'dd.MM.yyyy';

  return getFormattedDate(date, formattedDate);
};
