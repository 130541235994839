import React, { FC, PropsWithChildren } from 'react';

import { useAbstractStorage } from '../../../hooks';
import { settingsLayoutsConfigStorage } from '../../../store/settings';
import { SettingsConfigProvider } from '../provider/SettingsConfigProvider';

export const SettingsConfigService: FC<PropsWithChildren> = (props) => {
  const { data: settingsLayoutsData } = useAbstractStorage(settingsLayoutsConfigStorage.storage, {
    autoFetchAndRefetch: true,
  });

  return <SettingsConfigProvider {...props} config={{ layouts: settingsLayoutsData }} />;
};
