import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { Stringifiable } from 'query-string';

import { endpoints } from '../../endpoints';
import { FetchSelectEntityParams, DictPaginatedObjects, PaginationParams } from '../../types';
import {
  CreateDictionaryRecord,
  Dictionaries,
  DictsListStorageParams,
  DictsResponse,
  FetchDictsListStorageParams,
  RecordResponse,
} from '../../types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '../../utils';
import {
  createNewDictsRecord,
  UpdateDictionaryRecord,
  updateDictsRecord,
  deleteDictsRecord,
  DeleteDictionaryRecord,
} from './api';

export interface GetDictStorageParams<P extends Record<string, unknown>> {
  dictionaryName: Dictionaries.Name;
  dataBuilder?: (params: P) => Record<string, unknown>;
  getEndpointParams?: (params: P) => Record<string, Stringifiable>;
}

export const getDictsStorage = <T extends RecordResponse, P extends Record<string, unknown>>({
  dictionaryName,
  dataBuilder = (params) => params,
  getEndpointParams = () => ({}),
}: GetDictStorageParams<P>) => {
  const storage = abstractStorageFactory<DictPaginatedObjects<T>, T[], T[], P>({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(
        endpoints.dictionaries.dictionaryNameRecords(dictionaryName),
        getEndpointParams(params),
      ),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataBuilder: (params: P) => dataBuilder(params),
    dataMapper: (data) => data.objects,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount }),
  });

  const createNewDictsRecordEffect = createEffect<CreateDictionaryRecord, RecordResponse, AxiosError>(
    (params) => createNewDictsRecord({ ...params, dictionaryName }).then(({ data }) => data),
  );

  const updateDictsRecordEffect = createEffect<UpdateDictionaryRecord, RecordResponse, AxiosError>((params) =>
    updateDictsRecord({ ...params, dictionaryName }).then(({ data }) => data),
  );
  const deleteDictsRecordEffect = createEffect<DeleteDictionaryRecord, unknown, AxiosError>((params) =>
    deleteDictsRecord({ ...params, dictionaryName }).then(({ data }) => data),
  );

  storage.store
    .on(createNewDictsRecordEffect.doneData, (state, newDictItem) => ({
      ...state,
      data: [...state.data, newDictItem as T],
    }))
    .on(updateDictsRecordEffect.doneData, (state, updatedDictItem) => ({
      ...state,
      data: state.data.map((dictItem) =>
        dictItem.id === updatedDictItem.id ? { ...dictItem, updatedDictItem } : dictItem,
      ),
    }))
    .on(deleteDictsRecordEffect.done, (state, { params: { recordId } }) => ({
      ...state,
      data: state.data.filter((newsAdmin) => newsAdmin.id !== recordId),
    }));

  return { storage, createNewDictsRecordEffect, updateDictsRecordEffect, deleteDictsRecordEffect };
};

export const getDefaultDictStorages = <P extends FetchSelectEntityParams>({
  dictionaryStoragesParams,
}: {
  dictionaryStoragesParams: GetDictStorageParams<P>[];
}): Record<string, ReturnType<typeof getDictsStorage<RecordResponse, P>>> => {
  return Object.fromEntries(
    dictionaryStoragesParams.map((item) => [
      item.dictionaryName,
      getDictsStorage({
        dictionaryName: item.dictionaryName,
        dataBuilder: item.dataBuilder,
        getEndpointParams: item.getEndpointParams,
      }),
    ]),
  );
};

export const getDictsListByIdsStorage = () =>
  abstractStorageFactory<
    FetchDictsListStorageParams,
    RecordResponse[],
    RecordResponse[],
    DictsListStorageParams
  >({
    endpointBuilder: endpoints.dictionaries.recordsList,
    dataBuilder: ({ ids }) => ({ ids }),
    dataMapper: ({ items }) => items,
    requestMethod: 'post',
    defaultValue: [],
  });

export const getDictsListStorage = () =>
  abstractStorageFactory<
    DictPaginatedObjects<DictsResponse>,
    DictsResponse[],
    DictsResponse[],
    Partial<PaginationParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.dictionaries.dicts(), params),
    dataMapper: (data) => data.objects,
    defaultValue: [],
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount }),
  });

export type RecordsStorage = ReturnType<typeof getDictsStorage>;
