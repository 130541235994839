import { useLocation, matchRoutes } from 'react-router-dom';

import { RouteValues } from '../utils';

export const useMatchRoutes = (routeValues: RouteValues[]) => {
  const location = useLocation();

  const routeValuesObject = routeValues.map((route) => ({ path: route }));

  return matchRoutes(routeValuesObject, location);
};
