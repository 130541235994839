import { SkillId, UserIdModel } from '../types/models';

export const profilesEndpoints = {
  additionalInfo: (userId: UserIdModel) => `/api/profiles/${userId}/additional_info/`,
  biography: (userId: UserIdModel) => `/api/profiles/${userId}/biography/`,
  contactsSearch: () => `/api/profiles/contacts/search`,
  counters: (id: UserIdModel) => `/api/profiles/${id}/counters/`,
  job: (userId: UserIdModel) => `/api/profiles/${userId}/job/`,
  jobHistory: (userId: UserIdModel) => `/api/profiles/${userId}/job/history/`,
  listPositions: () => '/api/profiles/list/positions/',
  main: (userId: UserIdModel) => `/api/profiles/${userId}/main/`,
  openAPIToken: () => `/api/profiles/open-api/token/`,
  hobbySearch: () => '/api/profiles/hobby/search/',
  projectsSearch: () => '/api/profiles/projects/search/',
  projects: () => '/api/profiles/projects/',
  userIdSubstitutions: (userId: UserIdModel) => `/api/profiles/${userId}/substitutions/`,
  userIdContacts: (userId: UserIdModel) => `/api/profiles/${userId}/contacts/`,
  userIdFull: (userId: UserIdModel) => `/api/profiles/${userId}/full/`,
  userProjects: (userId: UserIdModel) => `/api/profiles/user/${userId}/projects/`,
  userSettings: () => '/api/profiles/user/settings/',
  v2List: () => '/api/profiles/v2/list/',
  v2Skills: (id: UserIdModel) => `/api/profiles/v2/${id}/skills/`,
  v2SkillAction: (id: UserIdModel, skillId: SkillId) => `/api/profiles/v2/${id}/skills/${skillId}/`,
  v2SkillPeoples: (id: UserIdModel) => `/api/profiles/v2/skills/${id}/people/`,
  v3AvatarCreate: (userId: UserIdModel) => `/api/profiles/v3/${userId}/avatar/create/`,
  v3AdvancedSearch: () => '/api/profiles/v3/advanced_search/',
  v3AdvancedSearchDownload: () => '/api/profiles/v3/advanced_search/download/',
};
