import { createEvent } from 'effector';

import { endpoints } from '../../endpoints';
import {
  CalendarEventCreateParams,
  CalendarEventModel,
  CalendarEventUpdateParams,
  CalendarStatusUpdate,
  CalendarEventPatchParams,
} from '../../types/models';
import { api } from '../../utils';

export const fetchCalendarEvent = (id: string) =>
  api.get<CalendarEventModel>({
    url: endpoints.calendar.eventId(id),
  });

export const addEventToCalendar = (id: string) =>
  api.post<void>({
    url: endpoints.calendar.eventIdAttendees(id),
  });

export const deleteEventFromCalendar = (id: string) =>
  api.delete<void>({
    url: endpoints.calendar.eventIdAttendees(id),
  });

export const createCalendarEvent = (params: CalendarEventCreateParams) => {
  const { siteId, ...data } = params;
  const url = siteId ? endpoints.calendar.eventsSitesSiteId(siteId) : endpoints.calendar.personalEventsNew();

  return api.post<CalendarEventModel>({
    url,
    data,
  });
};

export const updateCalendarEvent = ({ id, ...data }: CalendarEventUpdateParams) =>
  api.put<CalendarEventModel>({
    url: endpoints.calendar.eventId(id),
    data,
  });

export const updateMembersCalendarEvent = ({ id, ...data }: CalendarEventPatchParams) =>
  api.patch<CalendarEventModel>({
    url: endpoints.calendar.eventId(id),
    data,
  });

export const deleteCalendarEvent = (id: string) => api.delete({ url: endpoints.calendar.eventId(id) });

export const updateUserStatus = async (statuses: CalendarStatusUpdate) =>
  api.put({
    url: endpoints.calendar.eventsStatus(),
    data: statuses,
  });

export const refetchOnCreateUpdateMeetingEvent = createEvent();
