import { endpoints } from '../../endpoints';
import { BaseFieldParams, DictPaginated, Ordering, PaginationParams } from '../../types';
import {
  NewsArticle,
  NewsCategoriesDict,
  NewsCategoryListParams,
  CategoryModel,
  NewsCategoryId,
  SiteId,
  SiteNewsAuthors,
} from '../../types/models';
import {
  buildEndpointWithQueryParams,
  AbstractStorageConfiguration,
  abstractStorageFactory,
  abstractStoreFactory,
} from '../../utils';

export enum NewsFeedOrderingParams {
  isSuperCover = 'is_super_cover',
}

export interface NewsFeedListParams
  extends PaginationParams,
    Pick<BaseFieldParams, 'search'>,
    Ordering<NewsFeedOrderingParams> {
  type: string;
  important: boolean;
  pinned: boolean;
  category: string;
  categoryIds: string;
  authorId: string;
  siteId?: SiteId;
}

type GetNewsFeedStorageParams = Pick<
  AbstractStorageConfiguration<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    Partial<NewsFeedListParams>
  >,
  'shouldAppendData'
>;

type GetSiteNewsAuthorsParams = Pick<BaseFieldParams, 'search'>;

export const getNewsFeedStorage = (
  { shouldAppendData }: GetNewsFeedStorageParams = { shouldAppendData: true },
) => {
  const storage = abstractStorageFactory<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    Partial<NewsFeedListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.feed(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount, page: meta.pageNumber }),
    shouldAppendData,
  });

  return { storage };
};

export const getNewsCategoriesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CategoryModel<NewsCategoryId>>,
    CategoryModel<NewsCategoryId>[],
    CategoryModel<NewsCategoryId>[],
    Partial<NewsCategoryListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.categoryList(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  const paramsStore = abstractStoreFactory<Partial<NewsCategoryListParams>>({});

  return { storage, paramsStore };
};

export const getNewsCategoriesDictStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CategoryModel<NewsCategoryId>>,
    NewsCategoriesDict,
    null,
    Partial<NewsCategoryListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.categoryList(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items.reduce((acc, category) => ({ ...acc, [category.id]: category }), {}),
  });

  return { storage };
};

export const getSiteNewsAuthorsStorage = (siteId: SiteId) => {
  const storage = abstractStorageFactory<
    SiteNewsAuthors[],
    SiteNewsAuthors[],
    SiteNewsAuthors[],
    GetSiteNewsAuthorsParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.siteIdAuthors(siteId), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const paramsStore = abstractStoreFactory<GetSiteNewsAuthorsParams>({});

  return { storage, paramsStore };
};

export type NewsFeedStorage = ReturnType<typeof getNewsFeedStorage>;
export type NewsCategoriesStorage = ReturnType<typeof getNewsCategoriesStorage>;
export type NewsCategoriesDictStorage = ReturnType<typeof getNewsCategoriesDictStorage>;

export const newsCategoriesStore = getNewsCategoriesDictStorage();
