import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import {
  BaseFieldParams,
  DictPaginated,
  OrderingParams,
  PaginationParams,
  UiOptionDataExtended,
  CreateStageParams,
  ProjectCreateData,
  ProjectExtraData,
  ProjectStagesData,
  ProjectUpdateData,
  isUpdateProject,
  Ordering,
} from '../../types';
import {
  UserIdModel,
  UserModel,
  ProjectId,
  ProjectModel,
  ProjectParticipant,
  ProjectsTaskReferencesPrioritiesModel,
  TasksReferencesStatusId,
  ProjectsTaskReferencesStatusModel,
  ProjectsStageModel,
  ProjectsTaskCreateModel,
  ProjectsTaskId,
  ProjectsTasksModel,
  ProjectsTaskUpdateModel,
  ProjectsHistoryModel,
  ProjectsHistoryType,
  ProjectsHistoryId,
  ProjectsStageId,
} from '../../types/models';
import {
  buildEndpointWithQueryParams,
  EndpointQueryParamsBaseType,
  getFullName,
  abstractStorageFactory,
  abstractStoreFactory,
} from '../../utils';
import {
  createProject,
  createTask,
  updateProject,
  updateTask,
  deleteTask,
  updateProjectParticipants,
  deleteProject,
  createStage,
  updateStage,
  deleteStage,
} from './api';

type TasksFilterBaseParams = Partial<
  Pick<
    ProjectsTasksModel,
    'assignee' | 'author' | 'category' | 'priorityId' | 'projectId' | 'taskType' | 'stageId'
  >
>;

export type TasksFilterParams = Partial<PaginationParams> &
  TasksFilterBaseParams &
  Pick<BaseFieldParams, 'search'>;

export type GetTasksParams = Partial<TasksFilterParams>;
type GetTaskParams = { taskId: ProjectsTaskId };
type GetProjectParams = { projectId: ProjectId };

type StageIdParams = Pick<ProjectsStageModel, 'id'>;
export type UpdateStageParams = StageIdParams &
  Partial<Omit<ProjectsStageModel, 'id' | 'permissions' | 'tasks'>>;

export const getTasksStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProjectsTasksModel>,
    ProjectsTasksModel[],
    ProjectsTasksModel[],
    GetTasksParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.tasks.browse(), { ...params }),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const paramsStore = abstractStoreFactory<GetTasksParams>({});

  return { storage, paramsStore };
};

export type GetTasksStorage = ReturnType<typeof getTasksStorage>;

export const createTaskEffect = createEffect<ProjectsTaskCreateModel, ProjectsTasksModel, AxiosError>(
  (params) => createTask<ProjectsTasksModel>(params).then((response) => response.data),
);

export const updateTaskEffect = createEffect<ProjectsTaskUpdateModel, ProjectsTasksModel, AxiosError>(
  (params) => updateTask<ProjectsTasksModel>(params).then((response) => response.data),
);

export const deleteTaskEffect = createEffect<GetTaskParams, null, AxiosError>(({ taskId }) =>
  deleteTask(taskId).then((response) => response.data),
);

export const getTaskStorage = () => {
  const storage = abstractStorageFactory<ProjectsTasksModel, ProjectsTasksModel, null, GetTaskParams>({
    endpointBuilder: ({ taskId }) => endpoints.tasks.tasksId(taskId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type ReferencesStatusesDataMapper = {
  array: ProjectsTaskReferencesStatusModel[];
  hashMap: Map<TasksReferencesStatusId, ProjectsTaskReferencesStatusModel>;
};

export const getReferencesStatusesStorage = () => {
  const storage = abstractStorageFactory<
    ProjectsTaskReferencesStatusModel[],
    ReferencesStatusesDataMapper,
    ReferencesStatusesDataMapper
  >({
    endpointBuilder: () => endpoints.tasks.referencesStatuses(),
    dataMapper: (data) => ({
      array: data,
      hashMap: new Map(data.map((item) => [item.id, { ...item }])),
    }),
    defaultValue: { array: [], hashMap: new Map() },
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getReferencesPrioritiesStorage = () => {
  const storage = abstractStorageFactory<
    ProjectsTaskReferencesPrioritiesModel[],
    ProjectsTaskReferencesPrioritiesModel[],
    ProjectsTaskReferencesPrioritiesModel[]
  >({
    endpointBuilder: () => endpoints.tasks.referencesPriorities(),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export interface UpdateProjectParticipantsParams {
  projectId: ProjectId;
  participants: UserIdModel[];
}

export const updateProjectParticipantsEffect = createEffect<
  UpdateProjectParticipantsParams,
  DictPaginated<ProjectParticipant>,
  AxiosError
>((params) =>
  updateProjectParticipants<DictPaginated<ProjectParticipant>>(params).then((response) => response.data),
);

export const createStageEffect = createEffect<CreateStageParams, ProjectsStageModel, AxiosError>((params) =>
  createStage<ProjectsStageModel>(params).then(({ data }) => data),
);

export const updateStageEffect = createEffect<UpdateStageParams, ProjectsStageModel, AxiosError>((params) =>
  updateStage<ProjectsStageModel>(params).then(({ data }) => data),
);

export const deleteStageEffect = createEffect<ProjectsStageId, unknown, AxiosError>(deleteStage);

export interface GetProjectStagesParams extends EndpointQueryParamsBaseType {
  projectId?: ProjectId;
  year?: number;
  displayTasks?: boolean;
}

export const getProjectStagesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProjectsStageModel>,
    ProjectsStageModel[],
    ProjectsStageModel[],
    GetProjectStagesParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.tasks.stages(), params),
    dataMapper: ({ items }) => items,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const paramsStore = abstractStoreFactory<GetProjectStagesParams>({});

  return { storage, paramsStore };
};

export type GetProjectStagesStorage = ReturnType<typeof getProjectStagesStorage>;

interface GetProjectsStorageParams extends PaginationParams {
  isActive: boolean;
  isMine: boolean;
  status: number;
  title: string;
}

export const deleteProjectEffect = createEffect<GetProjectParams, unknown, AxiosError>(({ projectId }) =>
  deleteProject(projectId),
);

export const getProjectsForTaskCreationStorage = () => {
  const storage = abstractStorageFactory<
    ProjectModel<ProjectExtraData>,
    ProjectModel<ProjectExtraData>[],
    ProjectModel<ProjectExtraData>[],
    Partial<GetProjectsStorageParams>
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.tasks.projectsForTaskCreation(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getProjectsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProjectModel<ProjectExtraData>>,
    ProjectModel<ProjectExtraData>[],
    ProjectModel<ProjectExtraData>[],
    Partial<GetProjectsStorageParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.tasks.projects(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  const createProjectEffect = createEffect<ProjectCreateData, ProjectModel<ProjectExtraData>, AxiosError>(
    (params) => createProject<ProjectModel<ProjectExtraData>>(params).then(({ data }) => data),
  );

  storage.store
    .on(createProjectEffect.doneData, (state, project) => ({
      ...state,
      data: [...state.data, project],
    }))
    .on(deleteProjectEffect.doneData, (state, projectId) => ({
      ...state,
      data: state.data.filter((project) => project.id !== projectId),
    }));

  return { storage, createProjectEffect, deleteProjectEffect };
};

export type GetProjectsStorage = ReturnType<typeof getProjectsStorage>;

export const getProjectStorage = () => {
  const storage = abstractStorageFactory<
    ProjectModel<ProjectExtraData>,
    ProjectModel<ProjectExtraData>,
    null,
    GetProjectParams
  >({
    endpointBuilder: ({ projectId }) => endpoints.tasks.projectsId(projectId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const createProjectEffect = createEffect<ProjectCreateData, ProjectModel<ProjectExtraData>, AxiosError>(
    (params) => createProject<ProjectModel<ProjectExtraData>>(params).then(({ data }) => data),
  );

  const updateProjectEffect = createEffect<ProjectUpdateData, ProjectModel<ProjectExtraData>, AxiosError>(
    (params) =>
      updateProject<ProjectModel<ProjectExtraData>>(params.id, params).then((response) => response.data),
  );

  type CreateUpdateFullProjectParams = {
    project: ProjectCreateData | ProjectUpdateData;
    stages: ProjectStagesData[];
    participantIds: UserIdModel[];
  };

  const createUpdateFullProjectEffect = createEffect<CreateUpdateFullProjectParams, void, AxiosError>(
    async (params) => {
      const { project, stages, participantIds } = params;

      const updateStages: UpdateStageParams[] = [];
      const createStages: CreateStageParams[] = [];

      const { id: actualProjectId } = isUpdateProject(project)
        ? await updateProjectEffect(project)
        : await createProjectEffect(project);

      stages.forEach(({ id, ...rest }) => {
        id ? updateStages.push({ id, ...rest }) : createStages.push({ ...rest, projectId: actualProjectId });
      });

      const updateStagesList = updateStages.map(updateStageEffect);
      const createStagesList = createStages.map(createStageEffect);

      const projectStages = await Promise.all([...updateStagesList, ...createStagesList]);
      const stageIds = projectStages.map(({ id }) => id);

      await updateProjectEffect({ id: actualProjectId, stages: stageIds });
      await updateProjectParticipantsEffect({
        projectId: actualProjectId,
        participants: participantIds,
      });
    },
  );

  const { store } = storage;

  store.on(updateProjectEffect.doneData, (state, project) => {
    const { data } = state;

    return data ? { ...state, data: { ...data, ...project } } : state;
  });

  return { storage, updateProjectEffect, createUpdateFullProjectEffect };
};

export type GetProjectParticipantsParams = PaginationParams &
  Pick<BaseFieldParams, 'search'> &
  EndpointQueryParamsBaseType;

export const getProjectParticipantsStorage = (projectId: ProjectId) => {
  const storage = abstractStorageFactory<
    DictPaginated<ProjectParticipant>,
    ProjectParticipant[],
    ProjectParticipant[],
    GetProjectParticipantsParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.tasks.projectsIdParticipants(projectId), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export interface GetProjectParticipantsOptionsParams
  extends Partial<PaginationParams>,
    EndpointQueryParamsBaseType {
  projectId: ProjectId;
}

export const getProjectParticipantsOptionsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProjectParticipant>,
    UiOptionDataExtended<UserModel>[],
    UiOptionDataExtended<UserModel>[],
    GetProjectParticipantsOptionsParams
  >({
    endpointBuilder: ({ projectId, query }) =>
      buildEndpointWithQueryParams(endpoints.tasks.projectsIdParticipants(projectId), { query }),
    defaultValue: [],
    dataMapper: ({ items }) =>
      items.map((user) => ({ value: user.id, label: getFullName(user), data: user })),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

interface GetProjectHistoryParams
  extends Partial<PaginationParams>,
    EndpointQueryParamsBaseType,
    Partial<Ordering<OrderingParams>> {
  contentObject?: ProjectsHistoryType;
  objectId?: ProjectsHistoryId;
  projectId?: ProjectId;
}

export const getProjectHistoryStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProjectsHistoryModel>,
    ProjectsHistoryModel[],
    ProjectsHistoryModel[],
    GetProjectHistoryParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.tasks.history(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};
