import { FileStorageImage } from './file.model';
import { GroupId, GroupMemberModel } from './groups.model';

export type SelectedPath = { selectedPath: string[] };

export enum StructureSearchCategory {
  Employees = 'employees',
  Organization = 'organization',
}

export type SearchCategory = { searchCategory?: StructureSearchCategory };

export interface StructureListItem extends Partial<FileStorageImage> {
  id: string;
  name: string;
  parents: string[];
  groupType: string;
  mPath: string;
  hasChildren?: boolean;
  attributes: {
    count: number;
    image: string | null;
    smallAvatar?: string | null;
    mediumAvatar: string | null;
  };
}

export type StructureChildren = StructureListItem & {
  leaderAttrs?: GroupMemberModel;
  structureChildren?: StructureChildren[] | GroupMemberModel[];
};

export type StructureState = StructureChildren & SelectedPath & SearchCategory;

export type StructureResponse = {
  id: string;
  name: string;
  parents: string[];
  children?: StructureChildren[];
  hasChildren?: boolean;
  groupType: string;
  mPath: string;
  attributes: {
    count: number;
    image: string | null;
    smallAvatar: string | null;
    mediumAvatar: string | null;
  };
};

export type StructureUserPathModel = {
  id: GroupId;
  name: string;
};
