import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { AllAllowedPermissions, NavigationMenuGroupItem, NavigationSettingGroup } from '../../types/models';
import { abstractStorageFactory, abstractStoreFactory } from '../../utils';
import { UpdateFavoriteNavigationParams, updateFavoriteNavigation, updateSettingsNavigation } from './api';

type NavigationMenuUpdateStore = {
  isFavoritesEdit?: boolean;
  isFieldsChanged?: boolean;
};

export interface NavigationMenuSettingsGroupItem extends NavigationMenuGroupItem {
  permissions: AllAllowedPermissions[];
}

export const updateSettingsNavigationEffect = createEffect<
  NavigationSettingGroup[],
  NavigationSettingGroup[],
  AxiosError
>((params) => updateSettingsNavigation<NavigationSettingGroup[]>(params).then(({ data }) => data));

export const getNavigationListStorage = <T extends NavigationSettingGroup[]>() => {
  const storage = abstractStorageFactory<T, NavigationSettingGroup[], []>({
    endpointBuilder: endpoints.navigation.navigationMenu,
    defaultValue: [],
  });

  storage.store.on(updateSettingsNavigationEffect.doneData, (state, data) => ({ ...state, data }));

  return { storage };
};

export const getFavoriteNavigationStorage = <T extends NavigationMenuGroupItem[]>() => {
  const storage = abstractStorageFactory<T, T, []>({
    endpointBuilder: endpoints.navigation.navigationMenuBookmarks,
    defaultValue: [],
  });

  const paramsStore = abstractStoreFactory<NavigationMenuUpdateStore>({});

  const updateFavoriteNavigationEffect = createEffect<UpdateFavoriteNavigationParams, T, AxiosError>(
    (params) => updateFavoriteNavigation<T>(params).then(({ data }) => data),
  );

  storage.store.on(updateFavoriteNavigationEffect.doneData, (state, data) => ({ ...state, data }));

  return { storage, paramsStore, updateFavoriteNavigationEffect };
};

export const getNavigationMenuSettingsStorage = <T extends NavigationSettingGroup[]>() => {
  const storage = abstractStorageFactory<T, NavigationSettingGroup[], []>({
    endpointBuilder: endpoints.navigation.navigationMenuSettings,
    defaultValue: [],
  });

  storage.store.on(updateSettingsNavigationEffect.doneData, (state, data) => ({ ...state, data }));

  return { storage };
};
