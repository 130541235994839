import { BaseFieldParams, PaginationParams } from '../api';
import { EntityContentData } from '../editor';
import { CategoryModel } from './category.model';
import { CommentCountModel } from './comment.model';
import { Dictionaries } from './dictionary.model';
import { FileStorageEntryUrl } from './file.model';
import { PermissionRoleId } from './permissions.model';
import { PostSettings, PostTypes } from './post';
import { ReactableModel } from './reactions.model';
import { SiteId } from './sites.model';
import { BaseTag } from './tags.model';
import { BaseUserProfileModel, FullNameModel, UserIdModel, UserModel } from './user.model';

export enum NewsStatus {
  Published = 'published',
  Planned = 'planned',
  Draft = 'draft',
}

export interface NewsCategoryListParams
  extends Pick<BaseFieldParams, 'search' | 'ordering'>,
    Partial<PaginationParams> {
  activeStatus?: string;
  siteId?: SiteId;
}

export type NewsId = number;
export type NewsCategoryId = number;
export type NewsCategoriesDict = Record<NewsCategoryId, CategoryModel<NewsCategoryId>>;
export type NewsType = PostTypes.Simple | PostTypes.Stories;

export interface NewsCategoryParams extends Omit<CategoryModel<NewsCategoryId>, 'id'> {
  id?: NewsCategoryId;
}

type NewsArticleSettings = Pick<PostSettings, 'isComments' | 'isReacted'>;

export interface NewsArticle<Tags extends BaseTag = Dictionaries.Dict>
  extends ReactableModel,
    CommentCountModel {
  id: NewsId;
  title: string;
  cover: FileStorageEntryUrl;
  type?: NewsType;
  categoryId: NewsCategoryId;
  publishedAt: string;
  updatedAt?: string;
  createdAt: string;
  author: UserModel;
  important: boolean;
  pinned: boolean;
  timelinePinned: boolean;
  isSuperCover: boolean;
  tags: Tags[];
  body: EntityContentData;
  draft: boolean;
  authorId: string;
  status: NewsStatus;
  viewsCount: number;
  settings: NewsArticleSettings;
  siteId?: SiteId;
  overriddenRoleIds?: PermissionRoleId[];
}

export interface SiteNewsAuthors
  extends Pick<BaseUserProfileModel, 'fullName' | 'smallAvatar'>,
    FullNameModel {
  keycloakId: UserIdModel;
}
