import { AxiosError, AxiosResponse } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginated } from '../../types';
import {
  GroupId,
  DictBaseRecord,
  GroupDetailedInfoModel,
  GroupMemberModel,
  UserProfileModel,
  StructureChildren,
  StructureListItem,
  StructureResponse,
  StructureSearchCategory,
  StructureState,
  StructureUserPathModel,
} from '../../types/models';
import { AbstractStorageStoredData, abstractStorageFactory, buildEndpointWithQueryParams } from '../../utils';
import {
  getAffiliatesListEndpoint,
  getGroupDetailedEndpoint,
  getOrganizationsListEndpoint,
  getRootEndpoint,
  getStructureBySearch,
  getStructureListBySearch,
  getStructurePeopleBySearch,
  getStructureList,
  GroupParams,
  SelectParams,
  SelectPath,
  StructureSearchParams,
  GroupIdParams,
} from './api';

export type UserProfileGroupId = string;
export type UserProfileOrganizationId = string;

export interface GroupDetailedInfo extends GroupDetailedInfoModel {
  profileGroupId?: UserProfileGroupId;
  profileOrganizationId?: UserProfileOrganizationId;
}

interface StructureSearchStore extends Partial<Pick<UserProfileModel, 'townLiveRid'>> {
  searchCategory: StructureSearchCategory;
  isSearchOpen: boolean;
}

export const rootOrganizationStorage = abstractStorageFactory<StructureListItem, StructureListItem, null>({
  endpointBuilder: getRootEndpoint,
  defaultValue: null,
});

export type GetAffiliatesParams = {
  organizationId?: string;
};

export const getAffiliatesListStorage = () => {
  const storage = abstractStorageFactory<
    StructureListItem[],
    StructureListItem[],
    StructureListItem[],
    GetAffiliatesParams
  >({
    endpointBuilder: getAffiliatesListEndpoint,
    defaultValue: [],
    dataBuilder: (params) => ({ ...params }),
    requestMethod: 'post',
  });

  return {
    storage,
  };
};

export const getOrganizationsListStorage = () => {
  const storage = abstractStorageFactory<StructureListItem[], StructureListItem[], StructureListItem[]>({
    endpointBuilder: getOrganizationsListEndpoint,
    defaultValue: [],
  });

  return { storage };
};

export type GetChildOrganizationsParams = {
  parentId?: GroupId;
  query?: string;
};

export const getOrganizationsRecordsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<StructureListItem>,
    StructureListItem[],
    StructureListItem[],
    GetChildOrganizationsParams
  >({
    endpointBuilder: (params) => {
      return buildEndpointWithQueryParams(endpoints.orgstructure.organizationOrganizations(), params);
    },
    dataMapper: ({ items }) => items,
    defaultValue: [],
  });

  return { storage };
};

export const getOrganizationsPathStorage = () => {
  const storage = abstractStorageFactory<DictBaseRecord[], DictBaseRecord[], DictBaseRecord[], GroupIdParams>(
    {
      endpointBuilder: ({ groupId }) => endpoints.orgstructure.organizationIdOrganizationsPath(groupId),
      defaultValue: [],
    },
  );

  return { storage };
};

export const getGroupDetailedStorage = () => {
  const storage = abstractStorageFactory<GroupDetailedInfoModel, GroupDetailedInfo, null, GroupParams>({
    endpointBuilder: ({ groupId }) => getGroupDetailedEndpoint(groupId),
    defaultValue: null,
    requestMethod: 'post',
    dataMapper: (data) => {
      const groupPathList = data.mPath?.split('/');
      const profileGroupId = groupPathList?.at(-1);
      const profileOrganizationId = groupPathList?.at(0);

      return {
        ...data,
        profileGroupId,
        profileOrganizationId,
      };
    },
  });

  return {
    storage,
  };
};

const updateChildren = (
  parent: StructureChildren,
  path: string[],
  fetchedStructureItem: StructureResponse,
): StructureChildren[] | undefined => {
  return parent.structureChildren?.map((child) => {
    if (!path.includes(child.id)) {
      return child;
    }

    if (path.includes(child.id) && child.id === fetchedStructureItem.id) {
      return { ...child, ...fetchedStructureItem, structureChildren: fetchedStructureItem.children };
    }

    return {
      ...child,
      structureChildren: updateChildren(child as StructureChildren, path, fetchedStructureItem),
    };
  }) as StructureChildren[];
};

const createStateFromStructureData = (
  state: AbstractStorageStoredData<StructureState, null>,
  data: StructureResponse,
) => {
  const { children, ...restData } = data;

  return {
    ...state,
    data: {
      ...restData,
      structureChildren: children,
      selectedPath: [restData.id],
      searchCategory: StructureSearchCategory.Organization,
    },
  };
};

export const getStructureStorage = () => {
  const storage = abstractStorageFactory<StructureState, StructureState, null, GroupParams>({
    endpointBuilder: ({ groupId, ...params }) =>
      buildEndpointWithQueryParams(endpoints.orgstructure.list(), { id: groupId, ...params }),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const structureSearchState = createStore<StructureSearchStore>({
    searchCategory: StructureSearchCategory.Organization,
    isSearchOpen: false,
  });

  const changeSearchCategoryEvent = createEvent<StructureSearchCategory>();
  const clearStructureChildrenEvent = createEvent();
  const resetStructureEvent = createEvent();
  const searchOpenEvent = createEvent<{ isSearchOpen: boolean }>();
  const setFiltersEvent = createEvent<Partial<StructureSearchStore>>();

  storage.store
    .on(clearStructureChildrenEvent, (state) =>
      state.data
        ? {
            ...state,
            data: {
              ...state.data,
              structureChildren: [],
            },
          }
        : state,
    )
    .reset(resetStructureEvent);

  storage.fetchEffect.use((params) => {
    searchOpenEvent({ isSearchOpen: false });

    const listings: StructureResponse[] = [];

    const getChildrenRecursively = (_params: GroupParams): Promise<StructureResponse | void> =>
      getStructureList({
        groupId: _params.groupId,
        filter: _params.filter,
        excludeRoot: _params.excludeRoot,
      }).then(({ data }) => {
        listings.push(data);

        if (_params.organizationId && _params.organizationId === data.id) {
          return Promise.resolve();
        }

        if (data.parents.length) {
          return getChildrenRecursively({ ..._params, groupId: data.parents[0] });
        }

        return Promise.resolve();
      });

    return getChildrenRecursively(params).then(() => ({
      ...listings.reduce<StructureState>((prev, curr) => {
        const { children, ...restCurr } = curr;
        const selectedPath = prev.selectedPath ? [curr.id, ...prev.selectedPath] : [curr.id];

        return {
          ...restCurr,
          selectedPath,
          structureChildren: children?.map((child) =>
            child.id === prev.id ? { ...child, structureChildren: prev.structureChildren } : child,
          ),
          searchCategory: StructureSearchCategory.Organization,
        };
      }, {} as StructureState),
    }));
  });

  const updateSelectedPath = createEvent<SelectPath>();

  const updateStructureChildrenEffect = createEffect<
    SelectParams,
    AxiosResponse<StructureResponse>,
    AxiosError
  >((params) => {
    const { groupId, selectedPath } = params;

    return getStructureList({ groupId }).then((response) => {
      updateSelectedPath({ selectedPath });

      return response;
    });
  });

  const fetchFilteredStructureEffect = createEffect<
    GroupParams,
    AxiosResponse<StructureResponse>,
    AxiosError
  >((params) => {
    searchOpenEvent({ isSearchOpen: false });

    return getStructureList(params);
  });

  const searchStructureEffect = createEffect<
    StructureSearchParams,
    StructureChildren[] | GroupMemberModel[],
    AxiosError
  >(({ isListSearch, search, ...restParams }) => {
    searchOpenEvent({ isSearchOpen: true });

    const { searchCategory } = structureSearchState.getState();

    if (searchCategory === StructureSearchCategory.Organization) {
      return isListSearch
        ? getStructureListBySearch({ search }).then(({ data }) => data)
        : getStructureBySearch({ search }).then(({ data }) => data.items);
    }

    return getStructurePeopleBySearch({ search, ...restParams }).then(({ data }) => data.items);
  });

  storage.store
    .on(fetchFilteredStructureEffect.doneData, (state, { data }) => createStateFromStructureData(state, data))
    .on(updateSelectedPath, (state, { selectedPath }) =>
      state.data
        ? {
            ...state,
            data: {
              ...state.data,
              selectedPath,
            },
          }
        : state,
    )
    .on(updateStructureChildrenEffect.done, (state, { params, result }) => {
      const { data } = result;

      if (!state.data) {
        return createStateFromStructureData(state, data);
      }

      return {
        ...state,
        data: {
          ...state.data,
          structureChildren: updateChildren(state.data, params.selectedPath, data),
        },
      };
    })
    .on(searchStructureEffect.doneData, (state, structureChildren) => {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          structureChildren,
          selectedPath: [state.data.id],
          searchCategory: structureSearchState.getState().searchCategory,
        },
      };
    });

  structureSearchState
    .on(changeSearchCategoryEvent, (state, searchCategory) => ({
      ...state,
      searchCategory,
    }))
    .on(searchOpenEvent, (state, { isSearchOpen }) => ({
      ...state,
      isSearchOpen,
    }))
    .on(setFiltersEvent, (state, filters) => ({
      ...state,
      ...filters,
    }));

  return {
    storage,
    structureSearchState,
    fetchFilteredStructureEffect,
    updateSelectedPath,
    updateStructureChildrenEffect,
    searchStructureEffect,
    changeSearchCategoryEvent,
    searchOpenEvent,
    clearStructureChildrenEvent,
    resetStructureEvent,
    setFiltersEvent,
  };
};

export type OrganizationsListStorage = ReturnType<typeof getOrganizationsListStorage>;
export type StructureStorage = ReturnType<typeof getStructureStorage>;

export const getStructureSearchStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<StructureListItem>,
    StructureListItem[],
    StructureListItem[],
    Partial<StructureSearchParams>
  >({
    endpointBuilder: endpoints.orgstructure.search,
    requestMethod: 'post',
    defaultValue: [],
    dataBuilder: (params) => params,
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

export type GetStructureUserPathParams = {
  userUnitPath: GroupId;
};

export const getStructureUserPathStorage = () => {
  const storage = abstractStorageFactory<
    StructureUserPathModel[],
    StructureUserPathModel[],
    StructureUserPathModel[],
    GetStructureUserPathParams
  >({
    endpointBuilder: endpoints.orgstructure.userPath,
    requestMethod: 'post',
    defaultValue: [],
    dataBuilder: (params) => params,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
