import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { BaseFieldParams, DictPaginated, Ordering } from '../../types';
import {
  ListColumnFieldType,
  ListColumnId,
  ListColumnModel,
  ListId,
  ListModel,
  ListRowId,
  ListRowModel,
  ListRowValues,
  UserIdModel,
} from '../../types/models';
import { abstractStorageFactory, abstractStoreFactory, buildEndpointWithQueryParams } from '../../utils';
import {
  copyColumn,
  createColumn,
  CreateListColumnParams,
  createRow,
  deleteColumn,
  deleteListRow,
  DeleteListRowParams,
  deleteListRows,
  DeleteListRowsBatchParams,
  ListColumnParams,
  toggleVisibleColumn,
  updateColumn,
  updateColumnsOrder,
  UpdateListColumnParams,
  UpdateListColumnsOrderParams,
  updateListRow,
  UpdateListRowParams,
} from './api';

export interface GetListRowsParams
  extends Pick<BaseFieldParams, 'search'>,
    Partial<Ordering<ListColumnId[]>> {
  filters?: Record<ListColumnId, string[] | undefined>;
  createdById?: UserIdModel;
}

export type GetListParams = { id: ListId };

export type ListRowParams = GetListParams & Partial<GetListRowsParams>;

type CreateRowEffectParams = {
  id: ListId;
  rowValues: ListRowValues;
  insertAfterId?: ListRowId;
};

export const defaultValueCell = {
  [ListColumnFieldType.Boolean]: false,
  [ListColumnFieldType.Datetime]: undefined,
  [ListColumnFieldType.Dictionary]: '',
  [ListColumnFieldType.Enum]: '',
  [ListColumnFieldType.File]: '',
  [ListColumnFieldType.Hyperlink]: '',
  [ListColumnFieldType.Numeric]: 0,
  [ListColumnFieldType.Text]: '',
  [ListColumnFieldType.User]: undefined,
};

export const getListColumnStorage = () => {
  const storage = abstractStorageFactory<ListColumnModel, ListColumnModel, null, ListColumnParams>({
    endpointBuilder: ({ id, columnId }) =>
      buildEndpointWithQueryParams(endpoints.lists.listIdColumnsId(id, columnId)),
    defaultValue: null,
  });

  return { storage };
};

export const getListColumnsStorage = () => {
  const storage = abstractStorageFactory<
    ListColumnModel,
    ListColumnModel[],
    ListColumnModel[],
    GetListParams
  >({
    endpointBuilder: ({ id }) => buildEndpointWithQueryParams(endpoints.lists.listIdColumns(id)),
    defaultValue: [],
  });

  const createListColumnEffect = createEffect<CreateListColumnParams, ListColumnModel, AxiosError>(
    (params) => {
      return createColumn(params).then(({ data }) => data);
    },
  );

  const updateListColumnEffect = createEffect<UpdateListColumnParams, ListColumnModel, AxiosError>(
    (params) => {
      return updateColumn(params).then(({ data }) => data);
    },
  );

  const deleteListColumnEffect = createEffect<ListColumnParams, unknown, AxiosError>((params) => {
    return deleteColumn(params).then(({ data }) => data);
  });

  const updateListColumnsOrderEffect = createEffect<UpdateListColumnsOrderParams, unknown, AxiosError>(
    (params) => updateColumnsOrder(params).then(({ data }) => data),
  );

  const toggleVisibleListColumnEffect = createEffect<ListColumnParams, ListColumnModel, AxiosError>(
    (params) => toggleVisibleColumn(params).then(({ data }) => data),
  );

  const copyListColumnEffect = createEffect<ListColumnParams, ListColumnModel, AxiosError>((params) =>
    copyColumn(params).then(({ data }) => data),
  );

  storage.store.on(updateListColumnsOrderEffect.done, (state, { params }) => {
    const columnsIdMap = new Map(state.data.map((column) => [column.id, column]));

    return {
      ...state,
      data: params.order.map((id) => columnsIdMap.get(id) as ListColumnModel),
    };
  });

  storage.store.on(createListColumnEffect.done, (state, { result }) => {
    return {
      ...state,
      data: [...state.data, result],
    };
  });

  storage.store.on(updateListColumnEffect.done, (state, { result: editedColumn }) => {
    return {
      ...state,
      data: state.data.map((column) => (column.id === editedColumn.id ? editedColumn : column)),
    };
  });

  storage.store.on(deleteListColumnEffect.done, (state, { params }) => {
    return {
      ...state,
      data: state.data.filter((column) => column.id !== params.columnId),
    };
  });

  storage.store.on(toggleVisibleListColumnEffect.done, (state, { result }) => {
    return {
      ...state,
      data: state.data.map((column) => (column.id === result.id ? result : column)),
    };
  });

  storage.store.on(copyListColumnEffect.done, (state, { result }) => {
    return {
      ...state,
      data: [...state.data, result],
    };
  });

  return {
    storage,
    copyListColumnEffect,
    createListColumnEffect,
    updateListColumnEffect,
    deleteListColumnEffect,
    updateListColumnsOrderEffect,
    toggleVisibleListColumnEffect,
  };
};

export type GetListColumnsStorage = ReturnType<typeof getListColumnsStorage>;
export type GetListRowsStorage = ReturnType<typeof getListRowsStorage>;

const removeGroupDuplicates = (items: ListRowModel[]) => {
  const groupMap = new Map();

  return items.filter((item) => {
    if (item.group) {
      const groupId = item.group?.id ? item.group.id : null;

      if (!groupMap.has(groupId)) {
        groupMap.set(groupId, item);
        return true;
      }

      return false;
    }

    return true;
  });
};

export const getListRowsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ListRowModel>,
    ListRowModel[],
    ListRowModel[],
    ListRowParams
  >({
    endpointBuilder: ({ id }) => buildEndpointWithQueryParams(endpoints.lists.listIdRowsGetData(id)),
    requestMethod: 'post',
    dataBuilder: ({ id: _, ...restParams }) => restParams,
    dataMapper: ({ items }) => {
      const result: ListRowModel[] = [];

      items.forEach((item) => {
        if (item.group) {
          result.push({
            ...item,
            key: item.id + item.group.id,
            children: items
              .filter((child) => child.group?.id === item.group.id)
              .map((el) => {
                return {
                  id: el.id,
                  key: el.id,
                  ...el.rowValues,
                };
              }),
          });
        } else {
          result.push(item);
        }
      });

      return removeGroupDuplicates(result);
    },
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const createRowEffect = createEffect<CreateRowEffectParams, ListRowModel, AxiosError>((params) => {
    return createRow(params).then(({ data }) => data);
  });

  const deleteListRowEffect = createEffect<DeleteListRowParams, unknown, AxiosError>((params) =>
    deleteListRow(params).then(({ data }) => data),
  );

  const deleteListRowsBatchEffect = createEffect<DeleteListRowsBatchParams, unknown, AxiosError>((params) =>
    deleteListRows(params).then(({ data }) => data),
  );

  const updateListRowEffect = createEffect<UpdateListRowParams, ListRowModel, AxiosError>((params) => {
    return updateListRow(params).then(({ data }) => data);
  });

  storage.store.on(updateListRowEffect.done, (state, { result: editedRow }) => {
    return {
      ...state,
      data: state.data.map((row) => (row.id === editedRow.id ? editedRow : row)),
    };
  });

  storage.store.on(createRowEffect.done, (state, { params, result }) => {
    if (params.insertAfterId === undefined) {
      return {
        ...state,
        data: [...state.data, result],
      };
    }

    const targetIndex = state.data.findIndex((item) => item.id === params.insertAfterId);
    const newData = [...state.data];

    newData.splice(targetIndex + 1, 0, result);

    return {
      ...state,
      data: newData,
    };
  });

  storage.store.on(deleteListRowEffect.done, (state, { params }) => {
    return {
      ...state,
      data: state.data.filter((row) => row.id !== params.listRowId),
    };
  });

  storage.store.on(deleteListRowsBatchEffect.done, (state, { params }) => {
    return {
      ...state,
      data: state.data.filter((row) => !params.rowIds.includes(row.id)),
    };
  });

  const paramsStore = abstractStoreFactory<GetListRowsParams>({});

  return {
    storage,
    paramsStore,
    createRowEffect,
    deleteListRowEffect,
    deleteListRowsBatchEffect,
    updateListRowEffect,
  };
};

export const getListSlugStorage = () => {
  const storage = abstractStorageFactory<ListModel, ListModel, null, GetListParams>({
    endpointBuilder: ({ id }) => {
      return buildEndpointWithQueryParams(endpoints.lists.listsSlugId(id));
    },
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};
