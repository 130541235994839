import { AttachmentTypes } from './attachment.model';
import { CommentCountModel } from './comment.model';
import type { UTCDate } from './date.model';
import type { Dictionaries } from './dictionary.model';
import type { FileStorageEntryId, FileStorageListEntryModel } from './file.model';
import type { GroupInfoModel } from './groups.model';
import type { UpdateIssuerDateModel } from './issuerDate.model';
import type { TagId } from './tags.model';
import type { UserIdModel, FullNameModel, UserAvatarModel } from './user.model';

interface UserAuthorIdeaModel extends UserAvatarModel, FullNameModel {
  keycloakId: UserIdModel;
  isActive: boolean;
}

type OrganizationIdea = Pick<GroupInfoModel, 'id' | 'name'>;

export enum IdeasTabTypes {
  All = 'all',
  My = 'author',
  Drafts = 'drafts',
  Review = 'review',
  Sponsoring = 'sponsoring',
  Working = 'working',
}

export type IdeasTabCountsModel = Record<IdeasTabTypes, number>;

export enum IdeaStatusTypes {
  Draft = 'draft',
  Pending = 'pending',
  Review = 'review',
  Declined = 'declined',
  Progress = 'progress',
  Cancelled = 'cancelled',
  Completed = 'completed',
}
export enum IdeaTransitionNames {
  DraftToReview = 'draft-review',
  PendingToReview = 'pending-review',
  ReviewToProgress = 'review-progress',
  ProgressToCancelled = 'progress-cancelled',
  ProgressToCompleted = 'progress-completed',
  PendingToDraft = 'pending-draft',
  ProgressToPending = 'progress-pending',
  ReviewToDecline = 'review-decline',
  ReviewToPending = 'review-pending',
}

export enum IdeaCurrentVoteTypes {
  Up = '+',
  Down = '-',
}

export type IdeaModelId = string;
export type IdeaCategoryId = string;

export type IdeaCategoryModel = {
  id: IdeaCategoryId;
  name: string;
  isEnabled: boolean;
};

export type IdeaAttachmentModel = {
  id: FileStorageEntryId;
  attachmentType: AttachmentTypes;
  fileStorageObject: FileStorageListEntryModel;
};

export interface IdeaModel extends UpdateIssuerDateModel<UserIdModel>, CommentCountModel {
  id: IdeaModelId;
  status: IdeaStatusTypes;
  statusDescription: string | null;
  name: string;
  category: IdeaCategoryId;
  description: string;
  tags: Dictionaries.Dict[];
  isSubscribed: boolean;
  rating: number;
  attachments: IdeaAttachmentModel[];
  organization: OrganizationIdea | null;
  author: UserAuthorIdeaModel;
  reviewer: UserAuthorIdeaModel | null;
  reviewers: UserIdModel[];
  responsible: UserAuthorIdeaModel | null;
  sponsor: UserIdModel | null | boolean;
  sponsorComment: string | null;
  isReadStatusDescription: null | boolean;
  createdAt: string;
  group: string | null;
  userVote: IdeaCurrentVoteTypes | null;
  agreeCount: number;
  disagreeCount: number;
}

export type IdeaUploadAttachmentModel = {
  attachmentType: AttachmentTypes;
  fileStorageObjectId: FileStorageEntryId;
};

type IdeaCreateBaseModel = {
  name: string;
  description: string;
  category: IdeaCategoryId;
  attachments?: IdeaUploadAttachmentModel[];
  tags: TagId[];
  responsible?: string;
};

export interface IdeaCreateModel extends IdeaCreateBaseModel {
  status: IdeaStatusTypes;
}

export interface IdeaUpdateModel extends Partial<IdeaCreateBaseModel> {
  id: IdeaModelId;
}

export interface IdeaTransitionModel {
  id: IdeaModelId;
  transition: IdeaTransitionNames;
  statusDescription?: string;
  responsible?: string;
}

export interface IdeaApiTransitionModel extends Omit<IdeaTransitionModel, 'transition'> {
  transition: IdeaTransitionNames;
}
export interface IdeaVoteModel {
  id: IdeaModelId;
  value: IdeaCurrentVoteTypes;
}

export interface IdeaVoteResponseModel {
  createdAt: UTCDate;
  updatedAt: UTCDate;
  userId: UserIdModel;
  value: IdeaCurrentVoteTypes;
  disagreeCount: number;
  agreeCount: number;
  rating: number;
}

export type IdeaIsReadStatusDescriptionResponseModel = Pick<IdeaModel, 'statusDescription'>;
