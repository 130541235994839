import {
  FileStorageBlogContextModel,
  FileStorageContextServiceTypes,
  FileStorageListEntryContext,
  FileStorageSitesContextModel,
} from '../models/file.model';

export const isFileStorageBlogContext = (
  context?: FileStorageListEntryContext,
): context is FileStorageBlogContextModel => {
  return context?.service === FileStorageContextServiceTypes.Blogs;
};

export const isFileStorageSitesContext = (
  context?: FileStorageListEntryContext,
): context is FileStorageSitesContextModel => {
  return context?.service === FileStorageContextServiceTypes.Sites && 'siteSlug' in context;
};
