import { EntityId } from '../types/blogs';
import { EventCategoryId, UserIdModel } from '../types/models';

export const eventsEndpoints = {
  activityConfirmParticipation: (id: EntityId) => `/api/events/activity/${id}/confirm_participation/`,
  activityDeclineParticipation: (id: EntityId) => `/api/events/activity/${id}/decline_participation/`,
  activityEntityIdGuest: (id: EntityId) => `/api/events/activity/${id}/guest/`,
  activityEntityId: (id: EntityId) => `/api/events/activity/${id}/`,
  activityNew: () => `/api/events/activity/new/`,
  adminRolesUserId: (keycloakId: UserIdModel) => `/api/events/admin_roles/${keycloakId}/`,
  adminRolesList: () => `/api/events/admin_roles/list/`,
  adminRolesSave: () => `/api/events/admin_roles/save/`,
  eventEntityIdConfirmParticipation: (id: EntityId) => `/api/events/event/${id}/confirm_participation/`,
  eventEntityId: (id: EntityId) => `/api/events/event/${id}/`,
  eventIdCopy: (eventId: EntityId) => `/api/events/event/${eventId}/copy/`,
  eventIdDeclineParticipation: (id: EntityId) => `/api/events/event/${id}/decline_participation/`,
  eventIdGuest: (eventId: EntityId) => `/api/events/event/${eventId}/guest/`,
  eventIdV2InviteUsers: (eventId: EntityId) => `/api/events/event/${eventId}/v2/invite_users/`,
  eventIdMembersUserId: (eventId: EntityId, userId: UserIdModel) =>
    `/api/events/event/${eventId}/members/${userId}/`,
  eventIdV2Members: (eventId: EntityId) => `/api/events/event/${eventId}/v2/members/`,
  eventNew: () => `/api/events/event/new/`,
  eventIdParticipantsInfo: (eventId: EntityId) => `/api/events/event/${eventId}/participants_info/`,
  eventIdDecide: (eventId: EntityId) => `/api/events/event/${eventId}/decide/`,
  eventIdV2RequestUsers: (eventId: EntityId) => `/api/events/event/${eventId}/v2/request_users/`,
  eventsList: () => `/api/events/list/`,
  eventIdUsers: (eventId: EntityId) => `/api/events/event/${eventId}/users/`,
  eventEntityIdActivityList: (eventId: EntityId) => `/api/events/event/${eventId}/activity/list/`,
  eventsCategories: () => '/api/events/categories/',
  eventsCategoriesId: (eventCategoryId: EventCategoryId) => `/api/events/categories/${eventCategoryId}/`,
  eventIdRoles: (eventId: EntityId) => `/api/events/event/${eventId}/roles/`,
  eventsPermissionsRoles: () => '/api/events/permissions/roles/',
};
