import { UiFileDirection } from '@vkph/ui';
import React, { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';

type FileStorageDisplayTypeContextValueType = [
  displayType: UiFileDirection,
  setDisplayType?: (displayType: UiFileDirection) => void,
];

export const FileStorageDisplayTypeContext = createContext<FileStorageDisplayTypeContextValueType>([
  UiFileDirection.Vertical,
]);

export const FileStorageDisplayTypeProvider: FC<PropsWithChildren<{ value: UiFileDirection }>> = (props) => {
  const { children, value } = props;
  const [displayType, setDisplayType] = useState(value);

  const contextValue = useMemo<FileStorageDisplayTypeContextValueType>(
    () => [displayType, setDisplayType],
    [displayType],
  );

  return (
    <FileStorageDisplayTypeContext.Provider value={contextValue}>
      {children}
    </FileStorageDisplayTypeContext.Provider>
  );
};
