import { ModalDeleteProps, UiUploadFile } from '@vkph/ui';
import { FC, HTMLAttributes } from 'react';

import { FileStorageCreatePayload, FileStorageUpdatePayload } from '../../types/file-storage';
import { ImageUploadCropPayload } from '../../types/imageUploadCrop';
import {
  CategoryModel,
  UserIdModel,
  BlogId,
  BlogManager,
  BlogModel,
  BlogsManagerRolePermissions,
  CalendarEventEditFormValuesType,
  CalendarEventModel,
  CMSPageId,
  CMSSectionId,
  DictsResponse,
  EventModel,
  FileStorageCategoryModel,
  FileStorageEntryId,
  FileStorageEntryMove,
  PreloadedFileModel,
  GamificationAddedThank,
  GamificationAdminBadge,
  GamificationAdminThanks,
  IdeaModel,
  IdeaModelId,
  IdeaTransitionNames,
  PollModel,
  SkillType,
  SkillsUsersSteps,
  UserSkill,
  ProjectId,
  ProjectsTaskId,
  GroupsMailingId,
  SendingSurveyId,
  SurveyChoiceModalParams,
  SurveyId,
  Tag,
  TagRelation,
  SiteId,
  NewsId,
  EventCategoryId,
  NewsCategoryId,
  IdeaCategoryModel,
  BlogAlbumCategoryId,
  BlogAlbumCategoryModel,
} from '../../types/models';
import { Note } from '../../types/notes';
import { QueryParams } from '../../types/query';
import { ServiceRolesListItemId, ServiceRolesNames, ServiceRolesType } from '../../types/roles';
import { SummaryAnalyticsReportType } from '../../types/summaryAnalyticsReport';
import { FilesUploadStore } from '../../utils/effector';
import { GetBlogAlbumCategoriesStorage } from '../blogs';
import { GetFileStorageAllCategoriesStorage } from '../filestorage';

export enum GlobalModalAppearances {
  PageOverlay,
  PageOverlayNoClose,
  LargeModal,
  LargeAlternativeModal,
  SmallModal,
  MediumModal,
  FullscreenModal,
  Drawer,
}

export type GlobalModalState<P = null, QP extends QueryParams = QueryParams> = {
  isVisible: boolean;
  isHiding: boolean;
  payload: P;
  queryParams?: QP;
};

export type GlobalModalSettings = {
  appearance: GlobalModalAppearances;
  moduleName: string;
};

/**
 * Новые модалки заводить здесь,
 * тип пейлоада при открытии задавать в GlobalModalsStorePayloads,
 * а настройки (внешний вид, название модуля) в globalModalsSettings
 */
export enum GlobalModalNames {
  EventsList = 'EventsList',
  BlogAlbumCategoryList = 'BlogAlbumCategoryList',
  BlogAlbumCategoryCreateUpdate = 'BlogAlbumCategoryCreateUpdate',
  CreateUpdateBlog = 'CreateUpdateBlog',
  CreateUpdateBlogManagerPermissions = 'CreateUpdateBlogManagerPermissions',
  CalendarCreateUpdateMeeting = 'CalendarCreateUpdateMeeting',
  CalendarRequestEvent = 'CalendarRequestEvent',
  CalendarCreateUpdateTask = 'CalendarCreateUpdateTask',
  CalendarEventDetail = 'CalendarEventDetail',
  CreateUpdateEvent = 'CreateUpdateEvent',
  MembershipApplications = 'MembershipApplications',
  NewsEditor = 'NewsEditor',
  NewsCreateCategory = 'NewsCreateCategory',
  EventsCreateCategory = 'EventsCreateCategory',
  IdeasCreateCategory = 'IdeasCreateCategory',
  IdeaApprove = 'IdeaApprove',
  IdeaClose = 'IdeaClose',
  IdeaTransition = 'IdeaTransition',
  SurveyEditor = 'SurveyEditor',
  SurveyDetails = 'SurveyDetails',
  SurveyChoiceDetails = 'SurveyChoiceDetails',
  SurveyGroupsMailing = 'SurveyGroupsMailing',
  ProfileBadgeThanksView = 'ProfileBadgeThanksView',
  NoteEditor = 'NoteEditor',
  ProjectPreview = 'ProjectPreview',
  ProjectCreate = 'ProjectCreate',
  ProjectStages = 'ProjectStages',
  ProjectParticipants = 'ProjectParticipants',
  TaskCreate = 'TaskCreate',
  TaskPreview = 'TaskPreview',
  PollEditor = 'PollEditor',
  AdminOrgStructureCreateUpdateOrganization = 'AdminOrgStructureCreateUpdateOrganization',
  CreateUpdateSubdivision = 'CreateUpdateSubdivision',
  UpdateEmployee = 'UpdateEmployee',
  AdministrationCreatePermissionsAdmin = 'AdministrationCreatePermissionsAdmin',
  AdminGroupsPagesAdministrators = 'AdminGroupsPagesAdministrators',
  AdminPersonalPagesAdministrators = 'AdminPersonalPagesAdministrators',
  AdministrationCreateUserAgreement = 'AdministrationCreateUserAgreement',
  AdminServiceCreateRoles = 'AdminServiceCreateRoles',
  AdminServiceUpdateRoles = 'AdminServiceUpdateRoles',
  AdministrationPreviewUserAgreement = 'AdministrationPreviewUserAgreement',
  ProfilePreviewUserAgreementCurrent = 'ProfilePreviewUserAgreementCurrent',
  ProfilePreviewUserAgreementArchive = 'ProfilePreviewUserAgreementArchive',
  TextFormatHelp = 'TextFormatHelp',
  SurveyGroupMailingCreate = 'SurveyGroupMailingCreate',
  SummaryAnalyticsExportToExcel = 'SummaryAnalyticsExportToExcel',
  BlogMembers = 'BlogMembers',
  FileStorageUpdate = 'FileStorageUpdate',
  FileStorageCreate = 'FileStorageCreate',
  FileStorageCreateMultiple = 'FileStorageCreateMultiple',
  FileStorageMoveEntries = 'FileStorageMoveEntries',
  DictionaryViewRecords = 'DictionaryViewRecords',
  FileStorageCategoryList = 'FileStorageCategoryList',
  FileStorageCategoryCreateUpdate = 'FileStorageCategoryCreateUpdate',
  IdeaCreateUpdate = 'IdeaCreateUpdate',
  GroupsPagesCreateGroup = 'GroupsPagesCreateGroup',
  GroupsPagesCreateSection = 'GroupsPagesCreateSection',
  GroupsPagesCreatePage = 'GroupsPagesCreatePage',
  GroupsPagesDownloadReport = 'GroupsPagesDownloadReport',
  CreateUpdateGamificationThanks = 'CreateUpdateGamificationThanks',
  CreateUpdateGamificationBadge = 'CreateUpdateGamificationBadge',
  ImageUploadCrop = 'ImageUploadCrop',
  TagList = 'TagList',
  SkillsUsers = 'SkillsUsers',
  ConfirmDelete = 'ConfirmDelete',
}

export type GlobalModalsStorePayloads = {
  [GlobalModalNames.EventsList]: GlobalModalState<{ onSelect: (event: EventModel) => void }>;
  [GlobalModalNames.CreateUpdateBlog]: GlobalModalState<{
    existingBlogId?: BlogId;
    onSuccess?: () => void;
    onDelete?: () => void;
  }>;
  [GlobalModalNames.CreateUpdateBlogManagerPermissions]: GlobalModalState<{
    blogId: BlogId;
    user?: BlogManager;
    onSuccess: (blogManager: BlogManager) => void;
    permissions: Map<BlogsManagerRolePermissions['name'], BlogsManagerRolePermissions>;
  }>;
  [GlobalModalNames.CalendarCreateUpdateMeeting]: GlobalModalState<{
    initialEventFormValues?: CalendarEventEditFormValuesType;
    customSection?: FC<HTMLAttributes<HTMLDivElement>>;
    formClassName?: string;
    onSuccess?: () => void;
    siteId?: SiteId;
  }>;
  [GlobalModalNames.CalendarCreateUpdateTask]: GlobalModalState<{
    initialEventFormValues?: CalendarEventEditFormValuesType;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarRequestEvent]: GlobalModalState<{
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarEventDetail]: GlobalModalState<{
    event: CalendarEventModel;
    onSuccess?: () => void;
    isNewEvent?: boolean;
  }>;
  [GlobalModalNames.NewsEditor]: GlobalModalState<{
    newsId?: NewsId;
    siteId?: SiteId;
    onClose: () => void;
  }>;
  [GlobalModalNames.NewsCreateCategory]: GlobalModalState<{
    category?: CategoryModel<NewsCategoryId>;
    siteId?: SiteId;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.EventsCreateCategory]: GlobalModalState<{
    category?: CategoryModel<EventCategoryId>;
    onClose?: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.IdeasCreateCategory]: GlobalModalState<{
    category?: IdeaCategoryModel;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.IdeaApprove]: GlobalModalState<{
    ideaData?: IdeaModel;
    onClose: () => void;
  }>;
  [GlobalModalNames.IdeaClose]: GlobalModalState<{
    ideaData: IdeaModel;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.IdeaTransition]: GlobalModalState<{
    ideaData: IdeaModel;
    label: string;
    title: string;
    labelSubmit: string;
    transition: IdeaTransitionNames;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.SurveyEditor]: GlobalModalState<{
    surveyId?: SurveyId;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyDetails]: GlobalModalState<{
    sendingSurveyId: SendingSurveyId;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyChoiceDetails]: GlobalModalState<{
    choiceParams: SurveyChoiceModalParams;
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyGroupsMailing]: GlobalModalState<{
    surveyId?: SurveyId;
    onClose: () => void;
  }>;
  [GlobalModalNames.CreateUpdateEvent]: GlobalModalState<{
    event?: EventModel;
    isCopy?: boolean;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.MembershipApplications]: GlobalModalState<{
    blogId: string;
  }>;
  [GlobalModalNames.ProfileBadgeThanksView]: GlobalModalState<{
    onClose: () => void;
    thank: GamificationAddedThank;
  }>;
  [GlobalModalNames.NoteEditor]: GlobalModalState<{
    note?: Note;
    onClose: () => void;
    onSave: (note: Note) => void;
  }>;
  [GlobalModalNames.ProjectPreview]: GlobalModalState<{
    projectId: ProjectId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.ProjectCreate]: GlobalModalState<{
    projectId?: ProjectId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.ProjectStages]: GlobalModalState<{
    projectId: ProjectId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.ProjectParticipants]: GlobalModalState<{
    projectId: ProjectId;
  }>;
  [GlobalModalNames.TaskCreate]: GlobalModalState<{
    projectId?: ProjectId;
    isProjectEdit?: boolean;
    taskId?: ProjectsTaskId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.TaskPreview]: GlobalModalState<{
    taskId: ProjectsTaskId;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.PollEditor]: GlobalModalState<{
    poll?: PollModel;
    onSuccess: (poll: PollModel) => void;
  }>;
  [GlobalModalNames.AdminOrgStructureCreateUpdateOrganization]: GlobalModalState<{
    organizationId?: string;
    onSuccess: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.CreateUpdateSubdivision]: GlobalModalState<{
    subdivisionId?: string;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.UpdateEmployee]: GlobalModalState<{
    id: string;
    fullName: string;
    onSuccess: () => void;
    onClose: () => void;
    oldDepartmentId: string;
  }>;
  [GlobalModalNames.AdminServiceCreateRoles]: GlobalModalState<{
    serviceName: ServiceRolesNames;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdminServiceUpdateRoles]: GlobalModalState<{
    serviceName: ServiceRolesNames;
    id: ServiceRolesListItemId;
    type: ServiceRolesType;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdministrationCreatePermissionsAdmin]: GlobalModalState<{
    userId?: UserIdModel;
    onClose: () => void;
    serviceName: ServiceRolesNames;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdminGroupsPagesAdministrators]: GlobalModalState<{
    userId?: UserIdModel;
    serviceName: ServiceRolesNames;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdminPersonalPagesAdministrators]: GlobalModalState<{
    userId?: UserIdModel;
    serviceName: ServiceRolesNames;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdministrationCreateUserAgreement]: GlobalModalState<{
    onSuccess: () => void;
  }>;
  [GlobalModalNames.AdministrationPreviewUserAgreement]: GlobalModalState<{
    name: string;
    htmlText: string;
  }>;
  [GlobalModalNames.ProfilePreviewUserAgreementCurrent]: GlobalModalState<{
    onClose: () => void;
  }>;
  [GlobalModalNames.ProfilePreviewUserAgreementArchive]: GlobalModalState<Record<string, unknown>>;
  [GlobalModalNames.TextFormatHelp]: GlobalModalState<{
    onClose: () => void;
  }>;
  [GlobalModalNames.SurveyGroupMailingCreate]: GlobalModalState<{
    groupMailingId?: GroupsMailingId;
    isCopyAction?: boolean;
    onClose: () => void;
  }>;
  [GlobalModalNames.SummaryAnalyticsExportToExcel]: GlobalModalState<{
    report: SummaryAnalyticsReportType;
  }>;
  [GlobalModalNames.BlogMembers]: GlobalModalState<{
    blog: BlogModel;
  }>;
  [GlobalModalNames.FileStorageCreate]: GlobalModalState<FileStorageCreatePayload>;
  [GlobalModalNames.FileStorageUpdate]: GlobalModalState<FileStorageUpdatePayload>;
  [GlobalModalNames.FileStorageCreateMultiple]: GlobalModalState<{
    uploadStore: FilesUploadStore<PreloadedFileModel, UiUploadFile>;
    parent: FileStorageEntryId;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.FileStorageMoveEntries]: GlobalModalState<{
    rootId: FileStorageEntryId;
    rootName: string;
    moveItems: FileStorageEntryMove[];
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.DictionaryViewRecords]: GlobalModalState<{
    dictionary: DictsResponse;
    onClose: () => void;
    onSave: () => void;
  }>;
  [GlobalModalNames.FileStorageCategoryList]: GlobalModalState<{
    entryId: FileStorageEntryId;
    categoriesStorage: GetFileStorageAllCategoriesStorage;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.BlogAlbumCategoryList]: GlobalModalState<{
    entryId: BlogAlbumCategoryId;
    categoriesStorage: GetBlogAlbumCategoriesStorage;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.FileStorageCategoryCreateUpdate]: GlobalModalState<{
    entryId: FileStorageEntryId;
    category?: FileStorageCategoryModel;
    onSuccess?: (category: FileStorageCategoryModel) => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.BlogAlbumCategoryCreateUpdate]: GlobalModalState<{
    blogId: BlogId;
    category?: BlogAlbumCategoryModel;
    onSuccess?: (category: BlogAlbumCategoryModel) => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.IdeaCreateUpdate]: GlobalModalState<{
    ideaId?: IdeaModelId;
    onSuccess?: () => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.GroupsPagesCreateGroup]: GlobalModalState<{
    sectionId?: CMSSectionId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.GroupsPagesCreateSection]: GlobalModalState<{
    rootSectionId?: CMSSectionId;
    sectionId?: CMSSectionId;
    parentSectionId?: CMSSectionId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.GroupsPagesCreatePage]: GlobalModalState<{
    rootSectionId?: CMSSectionId;
    pageId?: CMSPageId;
    parentSectionId?: CMSSectionId;
    onClose: () => void;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.GroupsPagesDownloadReport]: GlobalModalState<{
    onClose: () => void;
    onSuccess?: () => void;
    sectionId: CMSSectionId;
  }>;
  [GlobalModalNames.CreateUpdateGamificationThanks]: GlobalModalState<{
    thanks?: GamificationAdminThanks;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.CreateUpdateGamificationBadge]: GlobalModalState<{
    badge?: GamificationAdminBadge;
    onClose: () => void;
    onSuccess: () => void;
  }>;
  [GlobalModalNames.ImageUploadCrop]: GlobalModalState<ImageUploadCropPayload>;
  [GlobalModalNames.TagList]: GlobalModalState<{
    value?: Tag[];
    onSelect?: (tags: Tag[]) => void;
    relations?: TagRelation[];
  }>;
  [GlobalModalNames.SkillsUsers]: GlobalModalState<{
    userId: UserIdModel;
    userSkill: UserSkill;
    skillType: SkillType;
    step?: SkillsUsersSteps;
  }>;
  [GlobalModalNames.ConfirmDelete]: GlobalModalState<ModalDeleteProps>;
};

export const globalModalsSettings: Record<GlobalModalNames, GlobalModalSettings> = {
  [GlobalModalNames.EventsList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'EventsList',
  },
  [GlobalModalNames.CalendarCreateUpdateMeeting]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'CalendarCreateUpdateMeeting',
  },
  [GlobalModalNames.CalendarCreateUpdateTask]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarCreateUpdateTask',
  },
  [GlobalModalNames.CalendarRequestEvent]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarRequestEvent',
  },
  [GlobalModalNames.CalendarEventDetail]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarEventDetail',
  },
  [GlobalModalNames.CreateUpdateBlog]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateBlog',
  },
  [GlobalModalNames.CreateUpdateBlogManagerPermissions]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateBlogManagerPermissions',
  },
  [GlobalModalNames.NewsEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'NewsEditor',
  },
  [GlobalModalNames.NewsCreateCategory]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'NewsCreateCategory',
  },
  [GlobalModalNames.EventsCreateCategory]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'EventsCreateCategory',
  },
  [GlobalModalNames.IdeasCreateCategory]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'IdeasCreateCategory',
  },
  [GlobalModalNames.IdeaApprove]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'IdeaApprove',
  },
  [GlobalModalNames.IdeaClose]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'IdeaClose',
  },
  [GlobalModalNames.IdeaTransition]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'IdeaTransition',
  },
  [GlobalModalNames.SurveyDetails]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyDetails',
  },
  [GlobalModalNames.SurveyChoiceDetails]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyChoiceDetails',
  },
  [GlobalModalNames.SurveyEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'SurveyEditor',
  },
  [GlobalModalNames.SurveyGroupsMailing]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyGroupsMailing',
  },
  [GlobalModalNames.CreateUpdateEvent]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateEvent',
  },
  [GlobalModalNames.MembershipApplications]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'MembershipApplications',
  },
  [GlobalModalNames.ProfileBadgeThanksView]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'ProfileBadgeThanksView',
  },
  [GlobalModalNames.NoteEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'NoteEditor',
  },
  [GlobalModalNames.ProjectPreview]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProjectPreview',
  },
  [GlobalModalNames.ProjectCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProjectCreate',
  },
  [GlobalModalNames.ProjectStages]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'ProjectStages',
  },
  [GlobalModalNames.ProjectParticipants]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProjectParticipants',
  },
  [GlobalModalNames.TaskCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'TaskCreate',
  },
  [GlobalModalNames.TaskPreview]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'TaskPreview',
  },
  [GlobalModalNames.PollEditor]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'PollEditor',
  },
  [GlobalModalNames.AdminOrgStructureCreateUpdateOrganization]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdminOrgStructureCreateUpdateOrganization',
  },
  [GlobalModalNames.CreateUpdateSubdivision]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateSubdivision',
  },
  [GlobalModalNames.UpdateEmployee]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdminOrgStructureUpdateEmployee',
  },
  [GlobalModalNames.AdminServiceCreateRoles]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminServiceCreateRoles',
  },
  [GlobalModalNames.AdminServiceUpdateRoles]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminServiceUpdateRoles',
  },
  [GlobalModalNames.AdministrationCreatePermissionsAdmin]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdministrationCreatePermissionsAdmin',
  },
  [GlobalModalNames.AdminGroupsPagesAdministrators]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminGroupsPagesAdministrators',
  },
  [GlobalModalNames.AdminPersonalPagesAdministrators]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminPersonalPagesAdministrators',
  },
  [GlobalModalNames.AdministrationCreateUserAgreement]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdministrationCreateUserAgreement',
  },
  [GlobalModalNames.AdministrationPreviewUserAgreement]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'AdministrationPreviewUserAgreement',
  },
  [GlobalModalNames.ProfilePreviewUserAgreementCurrent]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProfilePreviewUserAgreementCurrent',
  },
  [GlobalModalNames.ProfilePreviewUserAgreementArchive]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ProfilePreviewUserAgreementArchive',
  },
  [GlobalModalNames.TextFormatHelp]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'TextFormatHelp',
  },
  [GlobalModalNames.SurveyGroupMailingCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SurveyGroupMailingCreate',
  },
  [GlobalModalNames.SummaryAnalyticsExportToExcel]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'SummaryAnalyticsExportToExcelModal',
  },
  [GlobalModalNames.BlogMembers]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'BlogMembers',
  },
  [GlobalModalNames.FileStorageCreate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'FileStorageCreate',
  },
  [GlobalModalNames.FileStorageUpdate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'FileStorageUpdate',
  },
  [GlobalModalNames.FileStorageCreateMultiple]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'FileStorageCreateMultiple',
  },
  [GlobalModalNames.FileStorageMoveEntries]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'FileStorageMoveEntries',
  },
  [GlobalModalNames.DictionaryViewRecords]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'DictionaryViewRecords',
  },
  [GlobalModalNames.FileStorageCategoryList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'FileStorageCategoryList',
  },
  [GlobalModalNames.FileStorageCategoryCreateUpdate]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'FileStorageCategoryCreateUpdate',
  },
  [GlobalModalNames.BlogAlbumCategoryList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'BlogAlbumCategoryList',
  },
  [GlobalModalNames.BlogAlbumCategoryCreateUpdate]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'BlogAlbumCategoryCreateUpdate',
  },
  [GlobalModalNames.IdeaCreateUpdate]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'IdeaCreateUpdate',
  },
  [GlobalModalNames.GroupsPagesCreateGroup]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'GroupsPagesCreateGroup',
  },
  [GlobalModalNames.GroupsPagesCreateSection]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'GroupsPagesCreateSection',
  },
  [GlobalModalNames.GroupsPagesCreatePage]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'GroupsPagesCreatePage',
  },
  [GlobalModalNames.GroupsPagesDownloadReport]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'GroupsPagesDownloadReport',
  },
  [GlobalModalNames.CreateUpdateGamificationThanks]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CreateUpdateGamificationThanks',
  },
  [GlobalModalNames.CreateUpdateGamificationBadge]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CreateUpdateGamificationBadge',
  },
  [GlobalModalNames.ImageUploadCrop]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'ImageUploadCrop',
  },
  [GlobalModalNames.TagList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'TagList',
  },
  [GlobalModalNames.SkillsUsers]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'SkillsUsers',
  },
  [GlobalModalNames.ConfirmDelete]: {
    appearance: GlobalModalAppearances.SmallModal,
    moduleName: 'ConfirmDelete',
  },
};
