import { createEvent, createStore } from 'effector';

import { CalendarEventType, CalendarFreeSlot, MeetingRoomFreeSlot } from '../../../types/models';

export const getCalendarSelectedStorage = () => {
  const selectedSlotUpdated = createEvent<CalendarFreeSlot | null>();
  const selectedSlotStore = createStore<CalendarFreeSlot | null>(null).on(
    selectedSlotUpdated,
    (_, slot) => slot,
  );

  const selectedRoomUpdated = createEvent<MeetingRoomFreeSlot | null>();
  const selectedRoomStore = createStore<MeetingRoomFreeSlot | null>(null).on(
    selectedRoomUpdated,
    (_, room) => room,
  );

  const isSelectedRoomBusyUpdated = createEvent<boolean>();
  const isSelectedRoomBusyStore = createStore<boolean>(false).on(
    isSelectedRoomBusyUpdated,
    (_, isBusy) => isBusy,
  );

  const resetModalStoresEvent = createEvent();

  selectedSlotStore.reset(resetModalStoresEvent);
  selectedRoomStore.reset(resetModalStoresEvent);
  isSelectedRoomBusyStore.reset(resetModalStoresEvent);

  const isHiddenEarlyTimeSectionUpdated = createEvent();
  const isHiddenEarlyTimeSectionStore = createStore<boolean>(true).on(
    isHiddenEarlyTimeSectionUpdated,
    (isHidden) => !isHidden,
  );

  const eventTypesFilterUpdated = createEvent<CalendarEventType>();
  const eventTypesFilterInit = createEvent<CalendarEventType[]>();

  const eventTypesFilterStore = createStore<CalendarEventType[]>([
    CalendarEventType.Activity,
    CalendarEventType.Learn,
    CalendarEventType.Meeting,
    CalendarEventType.Task,
  ])
    .on(eventTypesFilterUpdated, (state, eventType) => {
      const filteredState = state.filter((value) => value !== eventType);

      return filteredState.length !== state.length ? filteredState : [...state, eventType];
    })
    .on(eventTypesFilterInit, (_, eventTypes) => {
      return eventTypes;
    });

  return {
    selectedSlotUpdated,
    selectedSlotStore,
    selectedRoomUpdated,
    selectedRoomStore,
    isSelectedRoomBusyUpdated,
    isSelectedRoomBusyStore,
    isHiddenEarlyTimeSectionUpdated,
    isHiddenEarlyTimeSectionStore,
    eventTypesFilterUpdated,
    eventTypesFilterInit,
    eventTypesFilterStore,
    resetModalStoresEvent,
  };
};

export type GetCalendarSelectedStorage = ReturnType<typeof getCalendarSelectedStorage>;
