import { FileStorageContextServiceTypes } from '../../types/models';
import { RouteNames } from '../../utils/router';

export const fileStorageContextRouteMap: Record<FileStorageContextServiceTypes, RouteNames> = {
  [FileStorageContextServiceTypes.Blogs]: RouteNames.GroupFile,
  [FileStorageContextServiceTypes.Projects]: RouteNames.ProjectsFile,
  [FileStorageContextServiceTypes.Profile]: RouteNames.ProfileFile,
  [FileStorageContextServiceTypes.FileStorage]: RouteNames.File,
  [FileStorageContextServiceTypes.Sites]: RouteNames.Home,
};

export enum HintType {
  SiteView = 'site-view',
  SiteEdit = 'site-edit',
  FilestorageFileView = 'filestorage-file-view',
  FilestorageFileEdit = 'filestorage-file-edit',
  FilestorageFolderView = 'filestorage-folder-view',
  FilestorageFolderEdit = 'filestorage-folder-edit',
}

const commonFolderHints = [
  'Просмотр файла в папке',
  'Реакция на файл в папке',
  'Комментирование файла в папке',
  'Просмотр вложенных папок',
  'Просмотр файлов во вложенных папках',
  'Реакция на файл во вложенных папках',
  'Комментирование файла во вложенных папках',
];

const commonFileHints = ['Просмотр файла', 'Реакция на файл', 'Комментирование файла'];
const commonSiteHints = [
  'Просмотр файлового хранилища',
  'Просмотр файла в файловом хранилище',
  'Реакция на файл в файловом хранилище',
  'Комментирование файла в файловом хранилище',
  'Просмотр вложенных папок в файловом хранилище',
  'Просмотр файлов во вложенных папках в файловом хранилище',
  'Реакция на файл во вложенных папках в файловом хранилище',
  'Комментирование файла во вложенных папках в файловом хранилище',
];

export const hintMap: Record<HintType, string[]> = {
  [HintType.FilestorageFileView]: commonFileHints,
  [HintType.FilestorageFileEdit]: [
    ...commonFileHints,
    'Редактирование файла',
    'Настройка доступов к файлу',
    'Перемещение файла',
    'Добавление новой версии файла',
    'Удаление файла',
  ],
  [HintType.FilestorageFolderView]: commonFolderHints,
  [HintType.FilestorageFolderEdit]: [
    ...commonFolderHints,
    'Добавление файла в папку',
    'Редактирование файла в папке',
    'Настройка доступов на вложенные файлы и папки',
    'Перемещение файла',
    'Добавление новой версии файла в папку',
    'Удаление файла в папке',
    'Создание вложенных папок',
    'Редактирование вложенных папок',
    'Перемещение вложенных папок',
    'Удаление вложенных папок',
    'Добавление файла во вложенную папку',
    'Редактирование файла во вложенной папке',
    'Добавление новой версии файла во вложенную папку',
    'Удаление файла из вложенной папки',
  ],
  [HintType.SiteView]: commonSiteHints,
  [HintType.SiteEdit]: [
    ...commonSiteHints,
    'Добавление файла в файловое хранилище',
    'Редактирование файла в файловом хранилище',
    'Настройка доступов на вложенные файлы и папки',
    'Перемещение файла в файловом хранилище',
    'Добавление новой версии файла в файловом хранилище',
    'Удаление файла в файловом хранилище',
    'Создание вложенных папок в файловом хранилище',
    'Редактирование вложенных папок в файловом хранилище',
    'Перемещение вложенных папок в файловом хранилище',
    'Удаление вложенных папок в файловом хранилище',
    'Добавление файла во вложенную папку в файловом хранилище',
    'Редактирование файла во вложенной папке в файловом хранилище',
    'Добавление новой версии файла во вложенную папку в файловом хранилище',
    'Удаление файла из вложенной папки в файловом хранилище',
  ],
};
