import { EntityId } from '../types/blogs';
import { BlogAlbumId, BlogId, BlogRegistrationId, BlogSlugId, PostTypes } from '../types/models';

const optional = (entityId?: EntityId) => (entityId ? `${entityId}/` : '');

export const asyncBlogsEndpoints = {
  attachmentsContentTypeEntityId: (contentType: PostTypes, id: EntityId) =>
    `/api/async-blogs/attachments/${contentType}/${id}/`,
  attachmentsContentTypeObjectIdEntityId: (contentType: PostTypes, objectId: EntityId, id: EntityId) =>
    `/api/async-blogs/attachments/${contentType}/${objectId}/${id}/`,
  blogIdAcceptInvite: (id: EntityId) => `/api/async-blogs/blog/${id}/accept_invite/`,
  blogAlbumId: (albumId: BlogAlbumId) => `/api/async-blogs/albums/${albumId}/`,
  blogAlbumIdImages: (albumId: BlogAlbumId) => `/api/async-blogs/albums/${albumId}/images/`,
  blogIdAlbums: (blogId: BlogId) => `/api/async-blogs/blog/${blogId}/albums/`,
  blogSlugAlbums: (blogSlugId: BlogSlugId) => `/api/async-blogs/blog/slug/${blogSlugId}/albums/`,
  blogAlbumImageId: (imageId: EntityId) => `/api/async-blogs/albums/images/${imageId}/`,
  blogAlbumIdUpload: (albumId: BlogAlbumId) => `/api/async-blogs/albums/${albumId}/upload/`,
  blogAvailableUsers: (blogId: BlogId) => `/api/async-blogs/blog/${blogId}/available_users/`,
  blogCategories: () => `/api/async-blogs/blog/categories/`,
  blogEntries: (blogId: EntityId) => `/api/async-blogs/blog/${blogId}/entries/`,
  blogEntryId: (id?: EntityId) => `/api/async-blogs/blog/entry/${optional(id)}`,
  blogEntryList: () => `/api/async-blogs/blog/entry/list/`,
  blogId: (id?: BlogId) => `/api/async-blogs/blog/${optional(id)}`,
  blogIdHistory: (id: EntityId) => `/api/async-blogs/blog/${id}/history/`,
  blogInvite: (id: EntityId) => `/api/async-blogs/blog/${id}/invite/`,
  blogParticipants: (id: EntityId) => `/api/async-blogs/blog/${id}/participants/`,
  blogParticipantsInfo: (id: EntityId) => `/api/async-blogs/blog/${id}/participants_info/`,
  blogRejectInvite: (id: EntityId) => `/api/async-blogs/blog/${id}/reject_invite/`,
  blogRegistrationExportXlsx: (blogId: BlogId, registrationId: BlogRegistrationId) =>
    `/api/async-blogs/blog/${blogId}/registration/${registrationId}/export/xlsx/`,
  blogRevokeInviteUser: (id: EntityId, userId: EntityId) =>
    `/api/async-blogs/blog/${id}/revoke_invite/user/${optional(userId)}`,
  blogRoles: () => `/api/async-blogs/blog-roles/`,
  blogSentInvitations: (id: EntityId) => `/api/async-blogs/blog/${id}/sent_invitations/`,
  blogSlug: (slug: string) => `/api/async-blogs/blog/slug/${slug}/`,
  blogSlugEntries: (blogSlug: string) => `/api/async-blogs/blog/slug/${blogSlug}/entries/`,
  blogSubscribeRequest: (id: EntityId, requestId?: EntityId) =>
    `/api/async-blogs/blog/${id}/subscribe-request/${optional(requestId)}`,
  entryIdChangePinStatus: (id: EntityId) => `/api/async-blogs/entry/${id}/change_pin_status/`,
  entrySearch: () => `/api/async-blogs/entry/search/`,
  favoriteList: () => `/api/async-blogs/favorite/list/`,
  list: () => `/api/async-blogs/list/`,
  micropostEntityId: (id?: EntityId) => `/api/async-blogs/micropost/${optional(id)}`,
  postContentTypeFavoriteStatusId: (contentType: PostTypes, id: EntityId) =>
    `/api/async-blogs/post/${contentType}/favorite_status/${id}/`,
  reportsTheBestAuthor: () => `/api/async-blogs/reports/the_best_author/`,
  reportsTopBlogs: () => `/api/async-blogs/reports/top_blogs/`,
  reportsTopPosts: () => `/api/async-blogs/reports/top_posts/`,
  settingsMain: () => `/api/async-blogs/settings/main/`,
  subscriptionsBlog: (userId: EntityId, id: EntityId) =>
    `/api/async-blogs/subscriptions/${userId}/blog/${id}/`,
  subscriptionsMyBlog: (id: EntityId) => `/api/async-blogs/subscriptions/my/blog/${id}/`,
  blogIdAlbumsCategories: (blogId?: BlogId) => `/api/async-blogs/blog/${blogId}/albums/categories/`,
  blogSlugAlbumsCategories: (blogSlugId: BlogSlugId) =>
    `/api/async-blogs/blog/slug/${blogSlugId}/albums/categories/`,
  albumsCategoriesId: (id: EntityId) => `/api/async-blogs/albums/categories/${id}/`,
};
