import { useContext } from 'react';

import { FileStorageDisplayTypeContext } from '../provider';

export const useFileStorageDisplayType = () => {
  const fileStorageDisplayTypeContext = useContext(FileStorageDisplayTypeContext);

  if (!fileStorageDisplayTypeContext) {
    throw new Error('Can not resolve FileStorageDisplayTypeContext into useFileStorageDisplayType hook');
  }

  return fileStorageDisplayTypeContext;
};
