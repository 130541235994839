import { endpoints } from '../../endpoints';
import { NavigationMenuGroupItem, NavigationSettingGroup } from '../../types/models';
import { api } from '../../utils';

export type BookmarkItemId = string;

export type UpdateFavoriteNavigationParams = { itemsIds: BookmarkItemId[] };

export const updateFavoriteNavigation = <T extends NavigationMenuGroupItem[]>(
  data: UpdateFavoriteNavigationParams,
) =>
  api.put<T>({
    url: endpoints.navigation.navigationMenuBookmarks(),
    data,
  });

export const updateSettingsNavigation = <T extends NavigationSettingGroup[]>(
  data: NavigationSettingGroup[],
) =>
  api.put<T>({
    url: endpoints.navigation.navigationMenuSettings(),
    data,
  });
