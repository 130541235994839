import { useCallback, useMemo, useState } from 'react';

import { useAbstractStorage } from '../../../hooks';
import { getUsersGroupsListOptionsStorage, GetUsersGroupsStorageParams } from '../../roles';

type UseUsersGroupsOptionsListStorageParams = {
  autoFetchParams?: GetUsersGroupsStorageParams;
};

export const useUsersGroupsOptionsListStorage = (params: UseUsersGroupsOptionsListStorageParams = {}) => {
  const { autoFetchParams } = params;

  const [searchQuery, setQuerySearch] = useState('');
  const { storage: listStorage } = useMemo(getUsersGroupsListOptionsStorage, []);

  const {
    data: options,
    fetchFx: fetchUsersGroupsList,
    loading,
  } = useAbstractStorage(listStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      ...autoFetchParams,
    },
  });

  const onUpdate = useCallback(
    (newSearchQuery: string) => fetchUsersGroupsList({ ...autoFetchParams, search: newSearchQuery }),
    [fetchUsersGroupsList],
  );

  return {
    options,
    storage: listStorage,
    onUpdate,
    searchQuery,
    setQuerySearch,
    loading,
  };
};
