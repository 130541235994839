import type { CMSPageFeedbackModel, CreateDateModel, ProjectsTasksModel, SitePageModel } from '.';
import type { BlogAlbumImageModel, BlogFullModel, BlogId, BlogModel } from './blog.model';
import type { CalendarEventModel } from './calendar.model';
import type { CMSPageModel } from './cms.model';
import type { CommentDetailListModel } from './comment.model';
import type { EventModel } from './event.model';
import type { FileStorageEntryVersionModel, FileStorageListEntryModel } from './file.model';
import type { GamificationAddedThank, GamificationBadges } from './gamification.model';
import type { IdeaModel } from './ideas.model';
import type { ListModel } from './lists.model';
import type { NewsArticle } from './news.model';
import type { PostBlogModel, MicropostModel, PostNewsModel } from './post';
import type { SkillType } from './profile.model';
import type { ReactionServiceModel } from './reactions.model';
import type { TimelineRecordModel, TimelineUserStructureContentModel } from './timelines.model';
import type { UserInitiatorModel } from './user.model';

export enum NotificationVersions {
  V1 = 1,
  V2 = 2,
}

export enum NotificationFetchStatuses {
  Unread = 'unread',
  Read = 'read',
  All = 'all',
}

export enum NotificationTypes {
  SkillAssignToUser = 'profile_skill_assign_to_user',
  SkillApproved = 'profile_skill_approved',
  CompetenceAssignToUser = 'profile_competence_assign_to_user',
  CompetenceApproved = 'profile_competence_approved',
  BlogEntryCreated = 'blog_blog_entry_created',
  BlogEntryUpdated = 'blog_blog_entry_update',
  BlogUserInvite = 'blog_blog_user_invite',
  BlogUserInviteExpert = 'blog_blog_user_invite_expert',
  TimelinesSignerMicropostCreated = 'timelines_signer_micropost_created',
  BlogUserInviteRequestClosedBlog = 'blog_blog_user_invite_request_closed_blog',
  BlogUserApproveRequestClosedBlog = 'blog_blog_user_approve_request_closed_blog',
  BlogUserRejectRequestClosedBlog = 'blog_blog_user_reject_request_closed_blog',
  BlogUserInviteModerator = 'blog_blog_user_invite_moderator',
  BlogEntryApproved = 'blog_entry_approved',
  BlogEntryDeclined = 'blog_entry_declined',
  BlogEntryUserMentioned = 'blog_entry_user_mentioned',
  BlogMicropostUserMentioned = 'blog_micropost_user_mentioned',
  BlogEntryRequireModeration = 'blog_entry_require_moderation',
  BlogGroupFileCreated = 'blog_group_file_created',
  BlogGroupAdministratorIsBlocked = 'blog_group_administrator_is_blocked',
  GamificationNewThanks = 'gamification_new_thanks',
  SurveyPublished = 'survey_survey_published',
  EventsInvite = 'events_events_invite',
  EventsDelete = 'events_events_delete',
  GamificationEventsManualGiveBadges = 'gamification_events_manual_give_badges',
  GamificationEventsAutomaticGiveBadges = 'gamification_events_automatic_give_badges',
  CalendarGroupEventAttendeeApproveDecision = 'calendar_group_event_attendee_approve_decision',
  CalendarGroupEventAttendeeRejectDecision = 'calendar_group_event_attendee_reject_decision',
  CalendarGroupEventAttendeeMaybeDecision = 'calendar_group_event_attendee_maybe_decision',
  CalendarGroupEventInvite = 'calendar_group_event_invite',
  CalendarGroupEventUpdated = 'calendar_group_event_updated',
  CalendarGroupEventDestroyed = 'calendar_group_event_destroyed',
  CalendarGroupEventComingSoon = 'calendar_group_event_coming_soon',
  CalendarGroupEventDeleteAttendee = 'calendar_group_event_delete_attendee',
  NewsUserMentioned = 'news_news_user_mentioned',
  FileStorageFileShared = 'filestorage_file_shared',

  // comments_comment_created
  CmsPageCommentCreated = 'comments_comment_created_cms',
  SitectorCommentCreated = 'comments_comment_created_sitector',
  FileVersionCommentCreated = 'comments_comment_created_file_version',
  NewsCommentCreated = 'comments_comment_created_news',
  BlogEntryCommentCreated = 'comments_comment_created_entry',
  MicropostCommentCreated = 'comments_comment_created_micropost',
  AlbumImageCommentCreated = 'comments_comment_created_album_image',
  ThanksCommentCreated = 'comments_comment_created_thanks',
  UserBadgeCommentCreated = 'comments_comment_created_user_badge',
  RecordCommentCreated = 'comments_comment_created_record',
  IdeaCommentCreated = 'comments_comment_created_idea',

  // comments_comment_reply
  CmsPageCommentReply = 'comments_comment_reply_cms',
  SitectorCommentReply = 'comments_comment_reply_sitector',
  FileVersionCommentReply = 'comments_comment_reply_file_version',
  NewsCommentReply = 'comments_comment_reply_news',
  BlogEntryCommentReply = 'comments_comment_reply_entry',
  MicropostCommentReply = 'comments_comment_reply_micropost',
  AlbumImageCommentReply = 'comments_comment_reply_album_image',
  ThanksCommentReply = 'comments_comment_reply_thanks',
  UserBadgeCommentReply = 'comments_comment_reply_user_badge',
  RecordCommentReply = 'comments_comment_reply_record',
  IdeaCommentReply = 'comments_comment_reply_idea',

  // comments_comment_user_mentioned
  CmsPageCommentUserMentioned = 'comments_comment_user_mentioned_cms',
  SitectorCommentUserMentioned = 'comments_comment_user_mentioned_sitector',
  FileVersionCommentUserMentioned = 'comments_comment_user_mentioned_file_version',
  NewsCommentUserMentioned = 'comments_comment_user_mentioned_news',
  BlogEntryCommentUserMentioned = 'comments_comment_user_mentioned_entry',
  MicropostCommentUserMentioned = 'comments_comment_user_mentioned_micropost',
  AlbumImageCommentUserMentioned = 'comments_comment_user_mentioned_album_image',
  ThanksCommentUserMentioned = 'comments_comment_user_mentioned_thanks',
  UserBadgeCommentUserMentioned = 'comments_comment_user_mentioned_user_badge',
  RecordCommentUserMentioned = 'comments_comment_user_mentioned_record',

  // reactions_reaction
  BlogEntryReactionCreated = 'reactions_reaction_entry_created',
  MicropostReactionCreated = 'reactions_reaction_micropost_created',
  ThanksReactionCreated = 'reactions_reaction_thanks_created',
  UserBadgeReactionCreated = 'reactions_reaction_user_badge_created',

  // reactions_reaction_comments
  CmsPageCommentReactionCreated = 'reactions_reaction_comment_reaction_cms_page_created',
  SitectorCommentReactionCreated = 'reactions_reaction_comment_reaction_sitector_page_created',
  BlogEntryCommentReactionCreated = 'reactions_reaction_comment_entry_created',
  MicropostCommentReactionCreated = 'reactions_reaction_comment_micropost_created',
  AlbumImageCommentReactionCreated = 'reactions_reaction_comment_reaction_album_image_created',
  NewsCommentReactionCreated = 'reactions_reaction_comment_news_created',
  FileVersionCommentReactionCreated = 'reactions_reaction_comment_file_version_created',
  TaskCommentReactionCreated = 'reactions_reaction_comment_reaction_task_created',
  ThanksCommentReactionCreated = 'reactions_reaction_comment_reaction_thanks_created',
  UserBadgeCommentReactionCreated = 'reactions_reaction_comment_reaction_user_badge_created',
  RecordCommentReactionCreated = 'reactions_reaction_comment_reaction_record_created',
  IdeaCommentReactionCreated = 'reactions_reaction_comment_reaction_idea_created',
  ProjectCommentReactionCreated = 'reactions_reaction_comment_reaction_project_created',

  // ideas_idea
  IdeaDecline = 'ideas_idea_decline',
  IdeaApprove = 'ideas_idea_approve',
  IdeaSetResponsible = 'ideas_idea_set_responsible',
  IdeaRequiredReview = 'ideas_idea_required_review',

  // tasks_tasks (Project Tasks)
  TasksCompleted = 'tasks_tasks_completed',
  TasksChangeAttributes = 'tasks_tasks_change_attributes',
  TasksAssignee = 'tasks_tasks_assignee',

  // cms
  CmsPageUserMentioned = 'cms_cms_page_user_mentioned',
  CmsFeedbackCreated = 'cms_cms_feedback_created',

  // lists_list
  ListRowCreated = 'lists_list_row_created',
  ListRowUpdated = 'lists_list_row_updated',
  ListRowDeleted = 'lists_list_row_deleted',
}

export enum NotificationBlogInviteState {
  Pending = 'pending',
  Declined = 'declined',
  Approved = 'approved',
  Revoked = 'revoked',
}

interface BaseContentModel {
  title: string;
  description: string;
}

export interface ContentSkillModel extends BaseContentModel {
  id: string;
  name: string;
  type: SkillType;
  approversCount: number;
  proveThreshold: number;
}

export interface ContentSurveyModel extends BaseContentModel {
  link: string;
  surveyType: string;
  questionsCount: string;
}

export interface LinkContentModel extends BaseContentModel {
  link: string;
}

export interface ExternalContentModel extends BaseContentModel {
  buttonLabel?: string;
  buttonLink?: string;
  externalId?: string;
}

type BaseCommentNotificationContentModel = {
  comment: CommentDetailListModel;
};

type BaseCommentReplyNotificationContentModel = {
  parent?: CommentDetailListModel;
};

type BaseReactionNotificationContentModel = {
  reaction: ReactionServiceModel;
};

interface NotificationFileModel extends FileStorageListEntryModel {
  isDeleted: boolean;
}

export type GroupFileCreatedContentModel = {
  file: NotificationFileModel;
  blog: BlogModel;
};

export interface NotificationFileVersionCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  fileVersion: FileStorageEntryVersionModel;
  file: NotificationFileModel;
}

export interface NotificationCmsPageCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  cms: CMSPageModel;
}

export interface NotificationNewsCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  news: NewsArticle;
}

export interface NotificationIdeaCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  idea: IdeaModel;
}

export interface NotificationTaskCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  task: ProjectsTasksModel;
}

export interface NotificationProjectCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  project: ProjectsTasksModel;
}

export interface NotificationBlogEntryCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  entry: PostBlogModel;
}

export interface NotificationMicropostCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  micropost: MicropostModel;
}

export interface NotificationUserBadgeCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  userBadge: GamificationBadges;
}

export interface NotificationThanksCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  thanks: GamificationAddedThank;
}

export interface NotificationTimelineRecordModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  timelineRecord: TimelineRecordModel<TimelineUserStructureContentModel>;
}

export interface NotificationAlbumImageCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  albumImage: BlogAlbumImageModel;
  blog: BlogModel;
}

export interface NotificationSitectorCommentModel
  extends BaseCommentNotificationContentModel,
    BaseCommentReplyNotificationContentModel {
  sitector: SitePageModel;
}

export type NotificationMicropostEntryModel = {
  micropost: MicropostModel;
};

export interface NotificationBlogEntryReactionModel extends BaseReactionNotificationContentModel {
  entry: PostBlogModel;
}

export interface NotificationMicropostReactionModel extends BaseReactionNotificationContentModel {
  micropost: MicropostModel;
}

export interface NotificationThanksReactionModel extends BaseReactionNotificationContentModel {
  thanks: GamificationAddedThank;
}

export interface NotificationUserBadgeReactionModel extends BaseReactionNotificationContentModel {
  userBadge: GamificationBadges;
}

export interface NotificationBlogInviteContentModel extends CreateDateModel {
  blog: BlogModel;
  id: BlogId;
  state: NotificationBlogInviteState;
}

export type NotificationCmsFeedbackModel = {
  cms: CMSPageModel;
  feedback: CMSPageFeedbackModel;
};

type NotificationCommentContentModel =
  | NotificationCmsPageCommentModel
  | NotificationFileVersionCommentModel
  | NotificationBlogEntryCommentModel
  | NotificationAlbumImageCommentModel
  | NotificationNewsCommentModel
  | NotificationMicropostCommentModel
  | NotificationUserBadgeCommentModel
  | NotificationThanksCommentModel
  | NotificationTimelineRecordModel
  | NotificationIdeaCommentModel
  | NotificationBlogEntryReactionModel
  | NotificationMicropostReactionModel
  | NotificationUserBadgeReactionModel
  | NotificationThanksReactionModel
  | NotificationTaskCommentModel
  | NotificationProjectCommentModel
  | NotificationSitectorCommentModel;

export type NotificationContentV1 =
  | ContentSkillModel
  | LinkContentModel
  | ContentSurveyModel
  | ExternalContentModel;
export type NotificationContentV2 =
  | EventModel
  | GamificationBadges
  | PostBlogModel
  | CalendarEventModel
  | NotificationBlogInviteContentModel
  | BlogFullModel
  | CMSPageModel
  | PostNewsModel
  | GroupFileCreatedContentModel
  | NotificationCommentContentModel
  | NotificationMicropostEntryModel
  | NotificationCmsFeedbackModel
  | ListModel
  | FileStorageListEntryModel
  | IdeaModel
  | MicropostModel
  | ProjectsTasksModel
  | SitePageModel;

export type NotificationContent = NotificationContentV1 | NotificationContentV2;

export type NotificationId = number;
export type NotificationModel<T extends NotificationContent = NotificationContent> = {
  id: NotificationId;
  initiator: UserInitiatorModel;
  image: string;
  status: number;
  content: T;
  routeUri: string;
  displayAvatar: boolean;
  createdAt: string;
  confirmStatus: number;
  notificationType: NotificationTypes;
  service?: string;
  version: NotificationVersions;
};

export enum NotificationSortAttributes {
  Date = 'createdAt',
  UnreadFirst = 'status,-created_at',
}

export type NotificationFilters = string[];

export type FetchNotificationParams = {
  notificationType?: string;
  pageSize?: number;
  pageNumber?: number;
  status?: NotificationFetchStatuses;
};

export enum NotificationStatuses {
  Unread = 0,
  Read = 1,
}
