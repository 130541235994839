import type { SinceTillFetchParams } from '../api';
import type { CalendarEventModelId } from './calendar.model';
import type { UserModel } from './user.model';

type DateString = string;
export type MeetingId = string;
export type MeetingFileId = number;
export type MeetingServiceId = string;
export type MeetingSelectedServiceId = number;
export type MeetingPhoneNumberId = number;
export type MeetingRoomFreeSlotId = number;

export enum MeetingScales {
  HalfAnHour = 'half_an_hour',
  Hour = 'hour',
  HourAndHalf = 'hour_and_half',
  TwoHours = 'two_hours',
}

export interface MeetingPhoneNumber {
  id: MeetingPhoneNumberId;
  number: string;
  label: string;
  meetingRoom: number;
}

export interface MeetingFile {
  id: MeetingFileId;
  attachmentFileURL: string;
  name: string;
  description: string;
  tags: string[];
  info: MeetingSelectedService;
  uploadedAt: DateString;
  updatedAt: DateString;
  size: number;
  attachmentFile: string;
  ctModelName: string;
  objectID: number;
}

export interface MeetingSelectedService {
  id: MeetingSelectedServiceId;
  name: string;
  file: MeetingFile;
  iconName: string;
}

export interface MeetingRoomImage {
  image: string;
  meetingRoom: number;
}

export interface Meeting {
  id: MeetingId;
  name: string;
  initiator: UserModel;
  dateTimeBegin: DateString;
  dateTimeEnd: DateString;
  meetingRoom: number;
  regulator: string;
  status: string;
  serviceBuffet: boolean;
  serviceConference: boolean;
  serviceInterpretation: boolean;
  selectedServices: MeetingSelectedService[];
  comment: string;
  calendarEventId: CalendarEventModelId;
}

export type ServiceTypes = 'video' | 'board' | 'display';

export interface MeetingService {
  id: MeetingServiceId;
  name: string;
  iconName: string;
  type: ServiceTypes;
  regulatorEmail: string;
  file: File;
}

export type FetchMeetingsParams = {
  excludedEventId?: CalendarEventModelId;
  name?: string;
  number?: string;
  serviceName?: string;
  serviceType?: ServiceTypes[];
  capacityTotal?: number;
  capacityTotalLte?: number;
  capacityTotalGte?: number;
  capacityTable?: number;
  space?: number;
  since?: string;
  till?: string;
  floor?: number;
  scale?: string;
};

export interface MeetingRoomFreeSlot extends SinceTillFetchParams {
  id: MeetingRoomFreeSlotId;
  name: string;
  description: string;
  floor: number;
  number: string;
  capacityTotal: number;
  capacityTable: number;
  space: number;
  phoneNumbers: MeetingPhoneNumber[];
  meetings: Meeting[];
  services: MeetingService[];
  fileStorageImageUrl: string;
  meetingRoomImages: MeetingRoomImage[];
}
