import axios, { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, Effect, Event, Store } from 'effector';

import { api } from '../api';

export type AbstractStorageParams = Record<string, unknown>;
export interface AbstractStoragePaginationInfo {
  next?: Record<string, unknown> | null;
  count?: number;
  page?: number;
  pageSize?: number;
  pageCount?: number;
}

export interface AbstractStorageConfiguration<FxResponseType, StoredDataType, DefaultValueType, Params> {
  endpointBuilder: (payload: Params) => string;
  defaultValue: DefaultValueType;
  requestMethod?: 'get' | 'post';
  dataBuilder?: (payload: Params) => Record<string, unknown>;
  dataMapper?: (data: FxResponseType) => StoredDataType;
  paginationInfoRetriever?: (data: FxResponseType) => AbstractStoragePaginationInfo;
  cancelPendingRequestOnFetch?: boolean;
  shouldAppendData?: boolean;
}

export interface AbstractStorageStoredData<StoredDataType, DefaultValueType> {
  data: StoredDataType | DefaultValueType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<any> | null;
  pagination: AbstractStoragePaginationInfo;
  lastRequestParams?: Record<string, unknown>;
  fetchRequestCount: number;
}

export type AbstractStorageStore<StoredDataType, DefaultValueType> = Store<
  AbstractStorageStoredData<StoredDataType, DefaultValueType>
>;

export type AbstractStorageEffect<Params, FxResponseType> = Effect<Params, FxResponseType, AxiosError>;

export type AbstractStorageResetEvent = Event<void>;

export type AbstractStorageFxCanceler = () => void;

export type AbstractStorage<
  FxResponseType,
  StoredDataType,
  DefaultValueType,
  Params extends Record<string, unknown> | void = void,
> = {
  fetchEffect: AbstractStorageEffect<Params, FxResponseType>;
  store: AbstractStorageStore<StoredDataType, DefaultValueType>;
  resetStoreEvent: AbstractStorageResetEvent;
  cancelPendingRequest: AbstractStorageFxCanceler;
  getLastRequestParams: () => Params;
  refetchWithLastParams: () => void;
};

const DEFAULT_PAGINATION_INFO: AbstractStoragePaginationInfo = {
  count: 0,
};

/**
 * Дока лежит в корне проекта по пути
 * /docs/abstract-storage.md
 * #Создание-инстанса-абстрактного-стораджа
 */
export const abstractStorageFactory = <
  FxResponseType,
  StoredDataType,
  DefaultValueType,
  Params extends AbstractStorageParams | void = void,
>({
  endpointBuilder,
  defaultValue,
  requestMethod = 'get',
  dataBuilder = () => ({}),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataMapper = (value: any) => value as StoredDataType,
  cancelPendingRequestOnFetch = false,
  paginationInfoRetriever,
  shouldAppendData,
}: AbstractStorageConfiguration<FxResponseType, StoredDataType, DefaultValueType, Params>): AbstractStorage<
  FxResponseType,
  StoredDataType,
  DefaultValueType,
  Params
> => {
  let abortController: AbortController | null = null;

  const cancelPendingRequest = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  const fetchEffect = createEffect<Params, FxResponseType, AxiosError>({
    handler: (params) => {
      if (abortController && cancelPendingRequestOnFetch) {
        abortController.abort();

        abortController = null;
      }

      abortController = new AbortController();

      return api[requestMethod]<FxResponseType>({
        url: endpointBuilder(params),
        signal: abortController.signal,
        data: dataBuilder(params),
      }).then(({ data }) => data);
    },
  });

  const resetEvent = createEvent();

  const store = createStore<AbstractStorageStoredData<StoredDataType, DefaultValueType>>({
    data: defaultValue,
    error: null,
    pagination: DEFAULT_PAGINATION_INFO,
    lastRequestParams: undefined,
    fetchRequestCount: 0,
  })
    .on(fetchEffect, (state, params) => ({
      ...state,
      error: null,
      lastRequestParams: params ? (params as Record<string, undefined>) : undefined,
      fetchRequestCount: state.fetchRequestCount + 1,
    }))
    .on(fetchEffect.done, (state, { result }) => {
      const mappedResult = dataMapper(result);
      const data =
        shouldAppendData && Array.isArray(state.data) && Array.isArray(mappedResult)
          ? (state.data.concat(mappedResult) as StoredDataType)
          : mappedResult;

      return {
        ...state,
        data,
        pagination: paginationInfoRetriever ? paginationInfoRetriever(result) : DEFAULT_PAGINATION_INFO,
        error: null,
      };
    })
    .on(fetchEffect.fail, (state, { error }) => {
      if (axios.isCancel(error)) {
        return state;
      }

      console.error(error);

      return {
        ...state,
        error,
      };
    })
    .reset(resetEvent);

  const getLastRequestParams = () => store.getState().lastRequestParams as Params;
  const refetchWithLastParams = () => fetchEffect(getLastRequestParams());

  return {
    fetchEffect,
    cancelPendingRequest,
    store,
    resetStoreEvent: resetEvent,
    getLastRequestParams,
    refetchWithLastParams,
  };
};
