import { SiteSlug, SiteServiceSlug, SiteServiceType, SiteId, SiteServiceId } from './models';

export type SitePreviewParams = {
  slug: SiteSlug;
  serviceType?: SiteServiceType;
  serviceSlug?: SiteServiceSlug;
};

export enum SiteTabs {
  Calendar = 'calendar',
  FileStorage = 'filestorage',
  General = 'general',
  Lists = 'lists',
  News = 'news',
  Pages = 'pages',
}

export type SitesParams = {
  id: SiteId;
  tab: SiteTabs;
  serviceType?: SiteServiceType;
  serviceId?: SiteServiceId;
  serviceSlug?: SiteServiceSlug;
};

export enum SiteNavbarEditorKey {
  Header = 'header',
}
