import { endpoints } from '../../endpoints';
import { DictPaginated, Ordering, PaginationParams } from '../../types';
import { GroupDivisionModel, GroupInfoModel, OrganizationId, OrganizationModel } from '../../types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '../../utils';

enum SearchFields {
  ManagementDepartment = 'management_department',
  Organization = 'organization',
  Name = 'name',
  Search = 'search',
}

type SearchParamsStore = Partial<Record<SearchFields, string>>;

type UserGroupsListStorageParams = {
  userId: string;
};

type StructureParams = {
  id: OrganizationId;
};

type OrgStructureSubdivisionListBaseParams = {
  organizationId?: string;
};

type OrgStructureSubdivisionListParams = OrgStructureSubdivisionListBaseParams &
  Partial<PaginationParams> &
  Pick<SearchParamsStore, SearchFields.ManagementDepartment | SearchFields.Organization> &
  Partial<Ordering>;

export const getUserGroupsListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupInfoModel>,
    GroupInfoModel[],
    GroupInfoModel[],
    UserGroupsListStorageParams
  >({
    endpointBuilder: ({ userId }) => endpoints.orgstructure.membersUserIdGroups(userId),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

/** @deprecated копипаста */
export const getOrgStructureSubdivisionsStorageOld = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupDivisionModel>,
    GroupDivisionModel[],
    GroupDivisionModel[],
    OrgStructureSubdivisionListParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.orgstructure.department(), params),
    dataMapper: (data) => data.items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
    defaultValue: [],
  });

  return { storage };
};

/** @deprecated копипаста */
export const getStructureStorageOld = () => {
  const storage = abstractStorageFactory<OrganizationModel, OrganizationModel, null, StructureParams>({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.orgstructure.list(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

/** @deprecated копипаста */
export const getOrganizationsRecordsStorageOld = () => {
  const storage = abstractStorageFactory<
    DictPaginated<OrganizationModel>,
    OrganizationModel[],
    OrganizationModel[]
  >({
    endpointBuilder: () => {
      return buildEndpointWithQueryParams(endpoints.orgstructure.organizationOrganizations());
    },
    dataMapper: ({ items }) => items,
    defaultValue: [],
  });

  return { storage };
};
