import { StringifiableRecord } from 'query-string';

import { BaseFieldParams, PaginationParams } from '../api';
import { EntityContentData } from '../editor';
import { OptionBase } from '../option';
import { PostTypes } from './post';
import {
  ProfileBiographyEducModel,
  ProfileBiographyLanguagesModel,
  ProfileJobHistoryModel,
} from './profile.model';
import { SiteId, SiteModel, SitePageId } from './sites.model';
import { JobModel, UserPositionId, UserProfileModel } from './user.model';

export enum SiteSearchField {
  All = 'all',
  Name = 'name',
  Description = 'description',
}

export enum ListsSearchField {
  Name = 'name',
  Description = 'description',
}

export enum NewsSearchField {
  Name = 'title',
  Description = 'body',
}

export enum PagesSearchField {
  Name = 'name',
  Description = 'body',
  All = 'all',
}

export interface SectionSearchParams<T> {
  sectionId?: T;
  showAllTree?: boolean;
}

export interface PagesSearchParams extends SectionSearchParams<SitePageId> {
  searchField?: PagesSearchField;
}

export interface SitesSearchParams extends SectionSearchParams<SiteId>, Partial<Pick<SiteModel, 'name'>> {
  searchFields?: SiteSearchField | NewsSearchField;
  category?: SearchCategoryType;
  siteId?: SiteId;
}

export interface ProfileSearchParams
  extends Pick<UserProfileModel, 'townLiveRid' | 'metroStationRid' | 'birthDate'>,
    Pick<ProfileJobHistoryModel, 'organizationRid'>,
    Pick<JobModel, 'dateEnd'>,
    Pick<ProfileBiographyLanguagesModel, 'levelRid'>,
    Pick<
      ProfileBiographyEducModel,
      'educationTypeId' | 'institutionId' | 'specializationId' | 'qualificationId'
    > {
  birthDayMonth: string;
  birthYear: string;
  hobbyRid: string[];
  languageId: ProfileBiographyLanguagesModel['languageId'][];
  skillRid: string[];
  competenceRid: string[];
  positionId: UserPositionId[];
  divisionRid: string;
  projectId: string[];
}

export type EventSearchParams = {
  isDraft?: boolean;
};

type EntitySearchParams = ProfileSearchParams & EventSearchParams & PagesSearchParams & SitesSearchParams;

export type SearchParams = Partial<PaginationParams> &
  Partial<EntitySearchParams> &
  Pick<BaseFieldParams, 'search'> &
  StringifiableRecord;

export enum SearchAllCategory {
  All = '_all',
  AllSites = '_allSites',
}

export enum SearchSingleCategory {
  Post = 'entry',
  Profile = 'profile',
  Comment = 'comment',
  Blog = 'blog',
  Files = 'files',
  News = 'news',
  Event = 'event',
  Tag = 'tag',
  Sites = 'sites',
  Pages = 'pages',
  Lists = 'lists',
}

export type SearchCategoryType = SearchSingleCategory | SearchAllCategory;

export type OriginListTyping = {
  type: string;
  title: string;
  listTitle?: string | null;
};

export type SearchListItem = {
  id: string;
  title: string;
  subTitle: string;
  image?: string;
  email?: string;
  isActive?: boolean;
};

export interface CommentSearchListItem extends Omit<SearchListItem, 'id'> {
  id: number;
  text: string;
  entryTitle: string;
  entryText: string;
  entryId: number;
  authorId: string;
}

export interface PostSearchModel extends Omit<SearchListItem, 'id'> {
  id: number;
  cutText: string;
  text: string;
  postTitle: string;
  blogTitle: string;
  blogId: number;
  blogSlug: string | null;
  authorId?: string;
  tags: OptionBase[];
  type: PostTypes.Entry;
  body?: EntityContentData;
}

export interface AttachmentSearchListItem extends Omit<SearchListItem, 'subTitle' | 'id'> {
  id: number;
  url: string;
  date: string;
  size: number;
}
