import { CommentCountModel } from './comment.model';
import {
  FileStorageEntryId,
  FileStorageEntryInfoModel,
  FileStorageListEntryContext,
  FileStorageListEntryModel,
} from './file.model';
import {
  CreateIssuerDateModel,
  CreateIssuerIdDateModel,
  UpdateIssuerDateModel,
  UpdateIssuerIdDateModel,
} from './issuerDate.model';
import { ReactableModel } from './reactions.model';
import { SiteId } from './sites.model';
import { UserModel, UserProfileModel } from './user.model';

export type ListId = string;
export type ListSlug = string;
export type ListColumnId = string;
export type ListRowId = string;
export type ListGroupId = string;

export enum ListStatus {
  Published = 'published',
  Draft = 'draft',
}

export enum ListServiceType {
  List = 'list',
}

export enum ListColumnFieldType {
  Text = 'text',
  Numeric = 'numeric',
  Boolean = 'boolean',
  User = 'user',
  Datetime = 'datetime',
  Enum = 'enum',
  Dictionary = 'dictionary',
  Hyperlink = 'hyperlink',
  File = 'file',
}

export enum DictionaryType {
  Position = 'position',
  Organization = 'organization',
  Department = 'department',
}

export type ListNotificationsSettings = {
  adminsAndEditors: boolean;
  listUsers: boolean;
};

export type ListColumnFieldOptions = {
  default: ListCellValues;
  maxLength: number;
  groupValues: boolean;
  range?: {
    minValue: number;
    maxValue: number;
  };
  folderId?: FileStorageEntryId;
  folder?: FileStorageListEntryModel<FileStorageListEntryContext>;
  trueValueName?: string;
  falseValueName?: string;
  onlyDate?: boolean;
  choices?: string[];
  dictionaryType?: DictionaryType;
};

export type DictionaryResponseCellValue = {
  id: string;
  dictionaryType: DictionaryType;
  dictionaryValueId: string;
  dictionaryValue: string;
};

type DictionaryRequestCellValue = {
  dictionaryType: DictionaryType;
  dictionaryValues: string[];
};

export type ListFileResponse = {
  id: FileStorageEntryId;
  name: string;
  file: string;
  fileData: FileStorageEntryInfoModel;
};

export type ListCellValues =
  | number
  | string
  | boolean
  | undefined
  | string[]
  | [boolean, string]
  | UserModel
  | DictionaryResponseCellValue[]
  | DictionaryRequestCellValue
  | ListFileResponse;

export type ListRowValues = Record<ListColumnId, ListCellValues>;

export interface ListModel
  extends CreateIssuerDateModel<UserProfileModel>,
    UpdateIssuerDateModel<UserProfileModel>,
    ReactableModel,
    CommentCountModel {
  id: ListId;
  name: string;
  description: string;
  slug: ListSlug;
  status: ListStatus;
  siteId: SiteId;
  notificationsSettings: ListNotificationsSettings;
}

export interface ListColumnModel extends CreateIssuerIdDateModel, UpdateIssuerIdDateModel {
  id: ListColumnId;
  listId: ListId;
  name: string;
  fieldType: ListColumnFieldType;
  fieldOptions: ListColumnFieldOptions;
  priority: number;
  isRequired: boolean;
  isVisible: boolean;
}

export interface ListRowModel extends CreateIssuerIdDateModel, UpdateIssuerIdDateModel {
  id: ListRowId;
  listId: ListId;
  key?: string;
  group: ListGroup;
  rowValues: ListRowValues;
  children?: { id: string; key: string; [x: string]: ListCellValues }[];
}

export type ListGroup = {
  id: ListGroupId;
};
