import { EntityId } from '../types';
import { NotificationsSettingsId } from '../types/models';

export const notificationsEndpoints = {
  feed: () => '/api/notifications/feed/',
  feedEntityIdMarkRead: (entityId: EntityId) => `/api/notifications/feed/${entityId}/mark_read/`,
  feedMarkReadAll: () => '/api/notifications/feed/mark_read_all/',
  settings: () => '/api/notifications/settings/',
  settingsId: (id: NotificationsSettingsId) => `/api/notifications/settings/${id}/`,
  settingsReset: () => '/api/notifications/settings/reset/',
};
